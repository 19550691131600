import React from "react";
import "../styles/dropdownbordado.css";
import { useState } from "react";

function DropdownBordado({ bordaded, setBordaded }) {
  const [isActive, setIsActive] = useState(false);
  const options = [
    "Bordado",
    "Sin Bordado",
  ];

  return (
    <div className="dropdown">
      
      <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
        
        {bordaded}
      </div>
      {isActive && (
        <div className="dropdown-content">
          {options.map((option) => (
            <div
              onClick={(e) => {
                setBordaded(option);
                setIsActive(false);
              }}
              className="dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownBordado;
