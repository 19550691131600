import React from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import producto1bloob from "../imagenes/PAGINA WEB JB/producto1bloob.jpg";
import producto2bloob from "../imagenes/PAGINA WEB JB/producto2bloob.jpg";
import producto3bloob from "../imagenes/PAGINA WEB JB/producto3bloob.jpg";

import producto1drop from "../imagenes/PAGINA WEB JB/producto1drop.jpg";
import producto2drop from "../imagenes/PAGINA WEB JB/producto2drop.jpg";
import producto3drop from "../imagenes/PAGINA WEB JB/producto3drop.jpg";

function rightproduct(seccion, lado) {
  {
    if (lado === "bloob") {
      if (seccion === "CAMISETAS SUBLIMADAS") {
        return (
          <Link className="productos-link" to="/bloob/productos">
          <h1 className="seccion-individual-titulo">{seccion}</h1>
          <div>
            <img className="imagen3" src={producto2bloob} />
          </div>
          </Link>
        );
      } else if (seccion == "CAMISETAS 100% SUBLIMADAS") {
        return (
          <Link className="productos-link" to="/bloob/productos">
          <h1 className="seccion-individual-titulo">{seccion}</h1>
          <div>
            <img className="imagen" src={producto3bloob} />
          </div>
          </Link>
        );
      } else if (seccion == "CAMISAS BASEBALL") {
        return (
          <Link className="productos-link" to="/bloob/productos">
          <h1 className="seccion-individual-titulo">{seccion}</h1>
          <div>
            <img className="imagen1" src={producto1bloob} />
          </div>
          </Link>
        );
      }
    }
    if (lado === "drop") {
      if (seccion === "CAMISETAS 100% SUBLIMADAS") {
        return (
          <Link className="productos-link" to="/drop/productos">
          <h1 className="seccion-individual-titulo">{seccion}</h1>
          <div>
            <img className="imagen4" src={producto1drop} />
          </div>
          </Link>
        );
      } else if (seccion == "CAMISETAS BORDADAS") {
        return (
          <Link className="productos-link" to="/drop/productos">
          <h1 className="seccion-individual-titulo">{seccion}</h1>
          <div>
            <img className="imagen5" src={producto2drop} />
          </div>
          </Link>
          
        );
      } else if (seccion == "CONJUNTOS (CAMISETA + SHORT)") {
        return (
          <Link className="productos-link" to="/drop/productos">
            <h1 className="seccion-individual-titulo">{seccion}</h1>
            <div>
              <img className="imagen6" src={producto3drop} />
            </div>
            </Link>
        );
      }
    }
  }
}
function ProductoIndividual(props) {
  return (
    <div className="parent-div">
      <div className="container-prod-ind">
        {rightproduct(props.seccion, props.lado)}
      </div>
    </div>
  );
}

export default ProductoIndividual;
