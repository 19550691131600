const catalogo = [
  {
    id: 1,
    title: "CAMISAS DE BASEBALL",
    price: "4.599",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: 'producto1Baseball',
    imgdownleft:"../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-2.jpg",
    imgdownright:"../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-3.jpg",
    desc: `Preparadas para un staff, ropa para utilizar previa a tus partidos o en los momentos de juego.
    Nuestra camiseta de Baseball en tela COOLDRY,
    te diferencia del resto.
    No es una prenda convencional, es una nueva manera de elevar el juego de tu equipo.`,
  },
  {
    id: 2,
    title: "CAMISETA BORDADA",
    price: "4.799",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: "../imagenes/productosIndividuales/productoIndividual4.png",
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles. Como diferencial, se le agrega el escudo del equipo bordado para lograr un acabado PREMIUM.`,
  },
  {
    id: 3,
    title: "CAMISETA BORDADA Y VINILADA",
    price: "5.399",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: "../imagenes/productosIndividuales/productoIndividual4.png",
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles. Como diferencial, se le agrega el escudo del equipo bordado para lograr un acabado PREMIUM.
    Además, los números, nombres y sponsors pueden ir colocados en VINILO.
    Una camiseta PROFESIONAL como los clubes de primera.`,
  },
  {
    id: 4,
    title: "CAMISETA FEMENINA",
    price: "3.999",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: '../imagenes/productosIndividuales/productoIndividual4.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Shabby chic keffiyeh neutra snackwave pork belly shoreditch. Prism austin mlkshk truffaut, `,
  },
  {
    id: 5,
    title: "CAMISETA NIÑO",
    price: "3.499",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: '../imagenes/productosIndividuales/productoIndividual5.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Ideal para los chicos, escuelitas, clubes que tengan categorías inferiores o simplemente un grupo de chicos que quiera jugar al fútbol.
    Este producto trabajado en tela Set Poliéster o Cooldry, se sublima con un método de calandrado para la impresión en los colores más vivos posibles.
    `,
  },
  {
    id: 6,
    title: "CAMISETA SUBLIMADA",
    price: "3.999",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: '../imagenes/bloobWelcome01.jpg',
    imgdownleft: './images/item-4.jpeg',
    imgdownright:'./images/item-4.jpeg',
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles.
    `,
  },
  {
    id: 7,
    title: "CONJUNTOS",
    price: "6.099",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: "../imagenes/productosIndividuales/productoIndividual8.png",
    imgdownleft:"../imagenes/productosIndividuales/productoIndividual1.png",
    imgdownright:"./images/item-4.jpeg",
    desc: `Con un formato de camiseta + short este conjunto te deja listo para salir a la cancha.
    Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles.`,
  },
  {
    id: 8,
    title: "SUDADERA",
    price: "3.099",
    pricea: "2.000",
    priceb: "1.900",
    imgtop: '..src/imagenes/productosIndividuales/productoIndividual5.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `on it tumblr kickstarter thundercats migas everyday carry squid palo santo leggings. Food truck truffaut  `,
  },
];
export default catalogo;
