//style of the app (some of the home)

import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

//to scrolltotop
import ScrollToTop from "./components/ScrollToTop";

//navbar de Bloob
import Navbar from "./components/Navbar";
//navbar de Drop
import NavbarDrop from "./components/NavbarDrop";

// footer
import FooterDrop from "./components/FooterDrop";

// import for the products
import catalogoLista from "./informacion/catalogo";
import catalogoListaDrop from "./informacion/catalogoDrop";

//all the imports below are for the Lading Welcome Page
import WelcomePage from "./components/WelcomePage";

//all this import below are for the HOME page only
import Video from "./components/Video";
import "./styles/video.css";

import Slogan from "./components/Slogan";
import SloganDrop from "./components/SloganDrop";

import ProductoIndividual from "./components/ProductoIndividual";
import "./styles/productoindividual.css";
import ProductoIndividualHome from "./components/ProductoIndividualHome";

import Vermasproductosbutton from "./components/Vermasproductosbutton";

import Direccion from "./components/Direccion";
import "./styles/direccion.css";

import Reviews from "./components/Reviews";
import "./styles/reviews.css";
//review drop
import ReviewsDrop from "./components/ReviewsDrop";

import Colaboraciones from "./components/Colaboraciones";
import "./styles/colaboraciones.css";

//productos individuales

import producto1drop from "./imagenes/PAGINA WEB JB/producto1drop.jpg";
import producto2drop from "./imagenes/PAGINA WEB JB/producto2drop.jpg";
import producto3drop from "./imagenes/PAGINA WEB JB/producto3drop.jpg";

import producto1bloob from "./imagenes/PAGINA WEB JB/producto1bloob.jpg";
import producto2bloob from "./imagenes/PAGINA WEB JB/producto2bloob.jpg";
import producto3bloob from "./imagenes/PAGINA WEB JB/producto3bloob.jpg";

//reviews
import user1 from "./imagenes/user1.png";
import user2 from "./imagenes/user2.png";
//colaboraciones
import colaboracionImagen from "./imagenes/PAGINA WEB JB/colaboracion-bloob.jpg";
//colaboracion Drop
import colaboracionImagenDrop from "./imagenes/PAGINA WEB JB/colaboracion-drop.jpg";
//all these imports below are for products
import ProductosBloob from "./components/ProductosBloob";
import "./styles/productosBloob.css";
// for products for DROP
import ProductosDrop from "./components/ProductosDrop";

//all these imports below are for tracking
import TrackingBloob from "./components/TrackingBloob";

//all these imports below are for PF
import PreguntasFrecuentesAca from "./components/PreguntasFrecuentes";

//all these imports below are for QS
import QuienesSomosBloob from "./components/QuienesSomosBloob";
import QuienesSomosDrop from "./components/QuienesSomosDrop";
//all these imports below are for Master
import Master from "./components/Master";
import VerProducto from "./components/VerProducto";
import Whatsappbutton from "./components/Whatsappbutton";
//
import { createContext } from "react";
import Footer from "./components/Footer";

//calculadora de precios
import CalculadorDePrecios from "./components/CalculadorDePrecios";

//navbar
import logoMiniatura from "./imagenes/bloobWelcome50px.jpeg";
import dropWelcome from "./imagenes/dropVECTOR1.png";

//editor 3d
import Editor from "./components/Editor";

// catalogo pdf
import CatalogoPreciosPdf from "./components/CatalogoPreciosPdf";
import PresentacionPdf from "./components/PresentacionPdf";
import CatalogoDisPdf from "./components/CatalogoDisPdf";
import Cart from "./components/Cart";
import CatalogoInteractivo from "./components/CatalogoInteractivo"
import { ShopContextProvider } from "./components/Shop-context";

// talles
import Talles from "./components/Talles";

///funcion para saber que navbar utilizar

function rightNavbar() {
  return (
    <div>
      <Navbar
        imagen={logoMiniatura}
        style={{ backgroundColor: "black" }}
        style2={{ color: "black" }}
      />
    </div>
  );
}

function App() {
  //esto es para pasar la info de los productos de la cuadricula y despues en individual
  const [catalogoItems, setCatalogoItems] = useState(catalogoLista);
  const [catalogoItemsDrop, setCatalogoItemsDrop] = useState(catalogoListaDrop);

  useEffect(() => console.log("app"), []);
  return (
    <div className="div-main">
      <ShopContextProvider>
        <Router className="App">
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<WelcomePage />} />

            <Route
              path="/bloob/home"
              element={
                <>
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Video video="videoBloob" />
                  <Whatsappbutton />
                  <Slogan frase="ELEVA TU JUEGO" />
                  {/*aca podria meter estos 3 productos individuales adentro de otro component*/}
                  <ProductoIndividualHome
                    seccion="CAMISETAS SUBLIMADAS"
                    imagen={producto1bloob}
                    lado="bloob"
                  />

                  <ProductoIndividualHome
                    seccion="CAMISETAS 100% SUBLIMADAS"
                    imagen={producto3bloob}
                    lado="bloob"
                  />
                  <ProductoIndividualHome
                    seccion="CAMISAS BASEBALL"
                    imagen={producto2bloob}
                    lado="bloob"
                  />
                  <Vermasproductosbutton infolado="bloob" />
                  <div className="multiple">
                    <Reviews
                      resume="Calidad fenomenal"
                      description="On the other hand, we denounce are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these m"
                      imagenProfile={user1}
                      nameProfile="Taco Pozo"
                    />
                    <Colaboraciones colaboracionImagen={colaboracionImagen} />
                  </div>

                  <Footer />
                </>
              }
            />
            <Route
              path="/drop/home"
              element={
                <>
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Video video="videoDrop" />
                  <Whatsappbutton />
                  <SloganDrop frase="CREAMOS TU HISTORIA" />
                  {/*aca podria meter estos 3 productos individuales adentro de otro component*/}
                  <ProductoIndividualHome
                    seccion="CAMISETAS 100% SUBLIMADAS"
                    imagen={producto1drop}
                    lado="drop"
                  />

                  <ProductoIndividualHome
                    seccion="CAMISETAS BORDADAS"
                    imagen={producto2drop}
                    lado="drop"
                  />
                  <ProductoIndividualHome
                    seccion="CONJUNTOS (CAMISETA + SHORT)"
                    imagen={producto3drop}
                    lado="drop"
                  />
                  <Vermasproductosbutton infolado="drop" />
                  <div className="multiple-container">
                    <div className="multiple">
                      <ReviewsDrop
                        resume="Calidad fenomenal"
                        description="On the other hand, we denounce are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these m"
                        imagenProfile={user1}
                        nameProfile="Taco Pozo"
                      />
                      <div className="colaboraciones-css">
                        <Colaboraciones
                          colaboracionImagen={colaboracionImagenDrop}
                        />
                      </div>
                    </div>
                  </div>

                  <FooterDrop />
                </>
              }
            />
            <Route
              path="/bloob/productos"
              element={
                <div>
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <ProductosBloob catalogoLista={catalogoItems} />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/drop/productos"
              element={
                <div>
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <ProductosDrop catalogoListaDrop={catalogoItemsDrop} />
                  <FooterDrop />
                </div>
              }
            />

            <Route
              path="/bloob/tracking/:urlId"
              element={
                <div className="parent-footer">
                  {rightNavbar()}
                  <Whatsappbutton />

                  <TrackingBloob />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/bloob/tracking"
              element={
                <div className="parent-footer">
                  {rightNavbar()}
                  <Whatsappbutton />

                  <TrackingBloob />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/tracking"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <TrackingBloob />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/drop/tracking/:urlId"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <TrackingBloob />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/bloob/quienes-somos"
              element={
                <div className="parent-footer">
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <QuienesSomosBloob />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/quienes-somos"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <QuienesSomosDrop />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/bloob/preguntas-frecuentes"
              element={
                <div className="parent-footer">
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <PreguntasFrecuentesAca />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/preguntas-frecuentes"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <PreguntasFrecuentesAca />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/masterDatabase"
              element={
                <div>
                  <Master />
                </div>
              }
            />
            <Route
              path="/bloob/productos/:id"
              element={
                <div>
                  {" "}
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />
                  <VerProducto />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/productos/:id"
              element={
                <div>
                  {" "}
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />
                  <VerProducto />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/bloob/calculadora-de-precios"
              element={
                <div className="parent-footer">
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <CalculadorDePrecios />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/calculadora-de-precios"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <CalculadorDePrecios />
                  <FooterDrop />
                </div>
              }
            />

            <Route
              path="/bloob/editor-de-camisetas"
              element={
                <div>
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <Editor />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/editor-de-camisetas"
              element={
                <div>
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <Editor />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/drop/catalogo-precios"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <CatalogoPreciosPdf />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/drop/catalogo"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <CatalogoDisPdf />
                  <FooterDrop />
                </div>
              }
            />
            <Route
              path="/drop/presentacion"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <PresentacionPdf />
                  <FooterDrop />
                </div>
              }
            />

            <Route
              path="/drop/catalogo-interactivo"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <CatalogoInteractivo />
                  <FooterDrop />
                </div>
              }
            />

            <Route
              path="/drop/cart"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />

                  <Cart />
                  <FooterDrop />
                </div>
              }
            />

            <Route
              path="/bloob/cart"
              element={
                <div className="parent-footer">
                  <Navbar
                    imagen={logoMiniatura}
                    style={{ backgroundColor: "black" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />
                  <Cart />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/drop/cart/talles"
              element={
                <div className="parent-footer">
                  <NavbarDrop
                    imagen={dropWelcome}
                    style={{ backgroundColor: "#E4FF00" }}
                    style2={{ color: "black" }}
                  />
                  <Whatsappbutton />
                  <Talles />
                  <FooterDrop />
                </div>
              }
            />
          </Routes>
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
