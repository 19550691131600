import React from "react";
import "../styles/QuienesSomos.css";
import quienesSomosimagen from "../imagenes/quienesSomos.png";
import { motion } from "framer-motion";

function QuienesSomosBloob() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="quienes-somos-container"
    >
      <h1 className="quienes-somos-title">QUIÉNES SOMOS</h1>
      <h3 className="quienes-somos-description">
        {" "}
        A partir de Bloob, continuamos nuestra historia hacia los deportes.
        Nuestra otra pasión. Siempre fue un sueño tener una camiseta con nuestro
        grupo de amigos, poder compartir cada partida con nuestra propia piel de
        eSports sintiéndonos parte de un equipo, unos colores, una comunidad,
        una familia. <br></br>
        <br></br>Buscando y buscando, no pudimos encontrar lo que buscábamos en
        ninguna marca. No podíamos encontrar esa sensación de sentirse parte de
        un espacio donde todos sigamos en la misma dirección, sintiendonos
        complacidos y escuchados para poder crear la camiseta de nuestros
        sueños.<br></br>
        <br></br> Decidimos dejar de buscar y crear nosotros la familia que
        queríamos, con mucho amor y cariño desde nuestros inicios buscamos crear
        un producto que identifique a cada uno de nuestros clientes que alguna
        vez tuvo esa sensación de buscar la camiseta que siempre quiso.<br></br>
        <br></br> Es el momento de que sus deseos se hagan realidad, venimos a
        crear la camiseta que siempre soñaron para su club, equipo, comunidad,
        realmente formando parte de una familia.<br></br>
        <br></br> Cada diseño es creado con mucho cariño como si fuera el
        primero y el único, porque así comenzó todo.
      </h3>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ delay: 0.3 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="imagen-container-qs"
      >
        <img className="imagen-faq" src={quienesSomosimagen} />
      </motion.div>
    </motion.div>
  );
}

export default QuienesSomosBloob;
