import React, { useState } from "react";
import people from "../informacion/ReviewDataDrop";
import { motion } from "framer-motion";

import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";

import reviewImage1 from "../imagenes/PAGINA WEB JB/REVIEWS/pobladores1.jpeg";
import reviewImage2 from "../imagenes/PAGINA WEB JB/REVIEWS/weir1.jpeg";
import reviewImage3 from "../imagenes/PAGINA WEB JB/REVIEWS/marloneta1.jpeg";


function Reviews(props) {
  const [index, setIndex] = useState(0);
  const { name, job, image, text } = people[index];
 
  const checkNumber = (number) => {
    if (number > people.length - 1) {
      return 0;
    }
    if (number < 0) {
      return people.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const randomPerson = () => {
    let randomNumber = Math.floor(Math.random() * people.length);
    if (randomNumber === index) {
      randomNumber = index + 1;
    }
    setIndex(checkNumber(randomNumber));
  };

  function imagenCorrecta() {
    {
      if (image === "imagen1") {
        return (
          <div>
            <img className="imagenProfile" src={reviewImage1} />
          </div>
        );
      } else if (image == "imagen2") {
        return (
          <div>
            <img className="imagenProfile" src={reviewImage2} />
          </div>
        );
      } else if (image == "imagen3") {
        return (
          <div>
            <img className="imagenProfile" src={reviewImage3} />
          </div>
        );
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="containerReview"
    >
      <div className="titleReview">{job}</div>
      <div className="descriptionReview">
        <button className="left-button-review" onClick={prevPerson}>
          <FaChevronLeft />
        </button>
        <p className="descripcionP">{text}</p>
        <button className="right-button-review" onClick={nextPerson}>
          <FaChevronRight />
        </button>
      </div>
      <div className="containerImagenProfile">
        <div>{imagenCorrecta(image)}</div>
        <div className="imagenProfileAbout">
          <p className="nombresito descripcionP">{name}</p>
          <p className="estrellitas descripcionP">⭐⭐⭐⭐⭐</p>
        </div>
      </div>
    </motion.div>
  );
}

export default Reviews;
