import React, { useContext } from "react";
import { ShopContext } from "./Shop-context";
import "../styles/cartItem.css";

const CartItem = (props) => {
  const { cartItems, addToCart, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);

  const { id, title, priceCart, imgCart } = props.data;

  return (
    <div className="cartItem">
      <div className="left-side-carrito">
        <div className="imagen-carrito-producto-container">
          <img className="imagen-carrito-producto" src={imgCart} />
        </div>

        <div className="div-titulo-izq-carrito">
          <h1 className="informacion-pedido"> {title}</h1>
        </div>
      </div>
      <h1 className="informacion-pedido"> ${priceCart}</h1>
      <div className="count-handler">
        <button
          className="boton-carrito-cantidad"
          onClick={() => removeFromCart(id)}
        >
          {" "}
          -{" "}
        </button>
        <input
          className="input-carrito-cantidad"
          type="number"
          value={cartItems[id]}
          onChange={(e) => updateCartItemCount(Number(e.target.value), id)}
        />
        <button
          className="boton-carrito-cantidad"
          onClick={() => addToCart(id)}
        >
          {" "}
          +{" "}
        </button>
      </div>
    </div>
  );
};

export default CartItem;
