import React, { useState } from "react";
import "../styles/Tracking.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";

//for website
import nuevopedido from "../imagenes/tracking/1nuevopedido.png";
import sublimacion from "../imagenes/tracking/2sublimacion.png";
import control1 from "../imagenes/tracking/control1.png";
import confeccion from "../imagenes/tracking/confeccion.png";
import control2 from "../imagenes/tracking/control2.png";
import listassinbordado from "../imagenes/tracking/listassinbordado.png";
import bordados from "../imagenes/tracking/bordados.png";
import listaconbordado from "../imagenes/tracking/listaconbordado.png";

//for cellphones
import nuevopedidoCell from "../imagenes/tracking/1nuevopedidoCell.png";
import sublimacionCell from "../imagenes/tracking/2sublimacionCell.png";
import control1Cell from "../imagenes/tracking/control1Cell.png";
import confeccionCell from "../imagenes/tracking/confeccionCell.png";
import control2Cell from "../imagenes/tracking/control2Cell.png";
import listassinbordadoCell from "../imagenes/tracking/listassinbordadoCell.png";
import bordadosCell from "../imagenes/tracking/bordadosCell.png";
import listaconbordadoCell from "../imagenes/tracking/listaconbordadoCell.png";

function TrackingBloob() {
  const [teamInfo, setTeamInfo] = useState();
  const [id, setId] = useState(undefined);
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answerStage, setAnswerStage] = useState("");
  const [showRightPic, setShowRightPic] = useState(false);
  const { urlId } = useParams();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    console.log(urlId);
    if (urlId) {
      setId(urlId);
      getOne(urlId);
    }
  }, []);

  const BACKEND_URL = "https://drop-track.onrender.com";

  const getOne = (id) => {
    setLoading(true);
    setShowInfo(false);
    Axios.get(`${BACKEND_URL}/team/${id}`, {}).then((response) => {
      setTeamInfo(response.data);
      setLoading(false);
      setShowInfo(true);
      setShowRightPic(true);
      setAnswerStage(response.data.teamStage);
    });
  };

  function rightPictureTracking() {
    if (answerStage == "Pedido") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={nuevopedido} />
        </div>
      );
    } else if (answerStage == "Sublimacion") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={sublimacion} />
        </div>
      );
    } else if (answerStage == "Control1") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={control1} />
        </div>
      );
    } else if (answerStage == "Confeccion") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={confeccion} />
        </div>
      );
    } else if (answerStage == "Control2") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={control2} />
        </div>
      );
    } else if (answerStage == "Listassinbordado") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={listassinbordado} />
        </div>
      );
    } else if (answerStage == "Bordados") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={bordados} />
        </div>
      );
    } else if (answerStage == "Listaconbordado"){
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={listaconbordado} />
        </div>
      );
    }
  }

  function rightPictureTrackingCell() {
    if (answerStage == "Pedido") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={nuevopedidoCell} />
        </div>
      );
    } else if (answerStage == "Sublimacion") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={sublimacionCell} />
        </div>
      );
    } else if (answerStage == "Control1") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={control1Cell} />
        </div>
      );
    } else if (answerStage == "Confeccion") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={confeccionCell} />
        </div>
      );
    } else if (answerStage == "Control2") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={control2Cell} />
        </div>
      );
    } else if (answerStage == "Listassinbordado") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={listassinbordadoCell} />
        </div>
      );
    } else if (answerStage == "Bordados") {
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={bordadosCell} />
        </div>
      );
    } else if (answerStage == "Listaconbordado"){
      return (
        <div className="div-proceso-prod">
          <img className="imagen-proceso-productivo" src={listaconbordadoCell} />
        </div>
      );
    }
  }

  return (
    <motion.div
    initial={{ opacity: 0 }}
    transition={{ delay: 0.3 }}
    whileInView={{ opacity: 1 }}
    exit={{ opacity: 0 }} className="tracking-container-container">
      <div className="tracking-container">
        <div className="center-this-bs">
          <div className="form-tracking">
            <form
              className="form-tracking-tracking"
              onSubmit={(e) => {
                e.preventDefault();
                getOne(id);
              }}
            >
              <h1 className="tracking-title">TRACKING</h1>
              <p className="frase-explicacion-tracking">
                {" "}
                En esta página podrás realizar el seguimiento de producción de
                tu pedido a través del ingreso de tu código de compra{" "}
              </p>

              <div className="center-input">
                <input
                  className="texto-a-insertar"
                  type="number"
                  placeholder="Código de seguimiento"
                  value={id}
                  onChange={(event) => {
                    setId(event.target.value);
                  }}
                />
              </div>
              <br />
              <div className="button-div-tracking">
                <input
                  type="submit"
                  className="button-3 extra-button-tracking"
                  value="Buscar"
                />
              </div>
            </form>
          </div>
        </div>
        {loading && (
          <div className="spinner-container">
            {" "}
            <p className="loading-spinner"></p>
          </div>
        )}
        <div className={showInfo ? "answer-showed" : "answer"}>
          {teamInfo ? (
            <>
              Bievenido <h3 className="team-name-ans">{teamInfo.teamName}</h3>{" "}
              Tu producto con código de seguimiento {teamInfo.code} se encuentra
              actualmente en el proceso de:
              <h2 className="estado-respuesta">{teamInfo.teamStage}</h2>
            </>
          ) : (
            <>
              <h3 className="not-a-code-text">
                No se encontró ninguna información con ese código de seguimiento
              </h3>
            </>
          )}
        </div>
        <div
          className={
            showRightPic ? "picture-tracking-showed" : "picture-tracking"
          }
        >
          {teamInfo ? (
            <>
              {isMobile ? (
                <>{rightPictureTrackingCell()}</>
              ) : (
                <>{rightPictureTracking()}</>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default TrackingBloob;
