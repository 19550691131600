const reviews = [
    {
      id: 1,
      name: 'POBLADORES',
      job: 'Compromiso',
      image:
        'imagen1',
      text:
        '"Quedaron de 10 Juan están muy buenas"',
    },
    {
      id: 2,
      name: 'WEIR',
      job: 'Hermosas',
      image:
        'imagen2',
      text:
        '"Querido llegaron las camisetas, están hermosas. Muchas gracias!"',
    },
    {
      id: 3,
      name: 'MARLONETA',
      job: 'Calidad',
      image:
        'imagen3',
      text:
        '"Hola buenasss! Ya nos llegaron las casacas, quedaron muy buenas, excelente calidad, muchas graciass :) :)"',
    },
  ];
  
  export default reviews;
  