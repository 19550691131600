import React from "react";
import "../styles/whatsappbutton.css";

function Whatsappbutton() {
  return (
    <div>
      <a href="https://wa.me/+5492214097465" className="whatsapp" target="_blank"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>
    </div>
  );
}

export default Whatsappbutton;
