import React from "react";
import "../styles/sloganDrop.css"

function SloganDrop(props) {
  return (
    <div>
      <div className="frase-drop">
        <h3 className="titulo-de-slogan-drop">{props.frase}</h3>
      </div>
    </div>
  );
}

export default SloganDrop;
