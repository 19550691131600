import React, { useState, useEffect } from "react";
import bannerProductos from "../imagenes/bannerProductos.jpeg";
import bannerBloob from "../imagenes/PAGINA WEB JB/banner-bloob.jpg";
import bannerDrop from "../imagenes/PAGINA WEB JB/banner-drop.jpg";
import CuadriculaProductosDrop from "./CuadriculaProductosDrop";
import catalogoLista from "../informacion/catalogo";
import { motion } from "framer-motion";

//imagenes productos

import producto1 from "../imagenes/productosIndividuales/productoIndividual4.png";
import producto2 from "../imagenes/productosIndividuales/productoIndividual5.png";
import producto3 from "../imagenes/productosIndividuales/productoIndividual6.png";
import producto4 from "../imagenes/productosIndividuales/productoIndividual7.png";

function ProductosDrop() {
  const [catalogoItems, setCatalogoItems] = useState(catalogoLista);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Add the setTimeout to hide the loader after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);
    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="cuadricula-superior"
    >
      {loading ? (
        <div className="spinner-container">
          {" "}
          <p className="loading-spinner"></p>
        </div>
      ) : (
        <>
          <div className="bannerImageDiv">
            <img className="bannerImagePic" src={bannerDrop} />
          </div>
          <div className="cuadricula-superior">
            <CuadriculaProductosDrop catalogoLista={catalogoItems} />
          </div>
        </>
      )}
    </motion.div>
  );
}

export default ProductosDrop;
