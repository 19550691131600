import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ShopContext } from "./Shop-context";
import CartItem from "./CartItem";
import catalogo from "../informacion/catalogo";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "../styles/cart.css";

const Cart = () => {
  const { cartItems, getTotalCartAmount } = useContext(ShopContext);

  const totalAmount = getTotalCartAmount();

  return (
    <div className="cart">
      <h1 className="titulo-carrito"> Carrito</h1>
      <div className="cart-items">
        {catalogo.map((catalogoItem) => {
          if (cartItems[catalogoItem.id] !== 0 || undefined) {
            return <CartItem data={catalogoItem} />;
          }
        })}
      </div>
      <div className="checkout">
        <h1 className=""> Subtotal: ${totalAmount.toFixed(3)} </h1>
        <div className="button-checkout-container">
          <Link to="/drop/productos">
            <button className="boton-checkout"> Seguir mirando</button>
          </Link>
          <Link to="/drop/cart/talles">
            <button className="boton-checkout"> Comprar</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
