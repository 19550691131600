//prod1
import producto1Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-1.jpg";
import producto2Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-2.jpg";
import producto3Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-3.jpg";
//prod2
import camisetabordada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada1.jpg";
import camisetabordada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada2.jpg";
import camisetabordada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada3.jpg";
//prod3
import camisetabordadayvinilada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada1.jpg";
import camisetabordadayvinilada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada2.jpg";
import camisetabordadayvinilada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada3.jpg";
//prod4
import camisetafemenina1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina1.jpg";
import camisetafemenina2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina2.jpg";
import camisetafemenina3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina3.jpg";
//prod5
import camisetanio1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio1.jpg";
import camisetanio2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio2.jpg";
import camisetanio3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio3.jpg";
//prod6
import camisetasublimada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada1.jpg";
import camisetasublimada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada2.jpg";
import camisetasublimada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada3.jpg";
//prod7
import conjuntos1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos1.jpg";
import conjuntos2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos2.jpg";
import conjuntos3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos3.jpg";
//prod8
import sudadera1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera1.jpg";
import sudadera2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera2.jpg";
import sudadera3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera3.jpg";


const catalogo = [
  {
    id: 1,
    title: "CAMISAS DE BASEBALL",
    price: "3.899",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 3899,
    priceCartTransferencia: 4599,
    imgtop: 'producto1Baseball',
    imgdownleft:"../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-2.jpg",
    imgdownright:"../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-3.jpg",
    desc: `Preparadas para un staff, ropa para utilizar previa a tus partidos o en los momentos de juego.
    Nuestra camiseta de Baseball en tela COOLDRY,
    te diferencia del resto.
    No es una prenda convencional, es una nueva manera de elevar el juego de tu equipo.`,
    imgCart: producto2Baseball,
  },
  {
    id: 2,
    title: "CAMISETA BORDADA",
    price: "4.799",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 4799,
    imgtop: "../imagenes/productosIndividuales/productoIndividual4.png",
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles. Como diferencial, se le agrega el escudo del equipo bordado para lograr un acabado PREMIUM.`,
    imgCart: camisetabordada1,

  },
  {
    id: 3,
    title: "CAMISETA BORDADA Y VINILADA",
    price: "5.399",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 5399,
    imgtop: "../imagenes/productosIndividuales/productoIndividual4.png",
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles. Como diferencial, se le agrega el escudo del equipo bordado para lograr un acabado PREMIUM.
    Además, los números, nombres y sponsors pueden ir colocados en VINILO.
    Una camiseta PROFESIONAL como los clubes de primera.`,
    imgCart: camisetabordadayvinilada2,

  },
  {
    id: 4,
    title: "CAMISETA FEMENINA",
    price: "3.999",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 3999,
    imgtop: '../imagenes/productosIndividuales/productoIndividual4.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Para ellas, para demostrar todo en la cancha y en cada partido dejar hasta la última gota. Con una molderia especializada para la mayor comodidad en una amplia gama de talles, nuestras camisetas en molderia femenina realizadas en tela Set Poliéster o Cooldry, se sublima en nuestras telas premium trabajadas con impresion profesional para acabados de alta calidad.
    - 100% personalizadas. - Tela ideal para los momentos de transpiración, con protección antibacterial, termorregulación y secado rápido. - Moldería masculina y femenina. - Packaging con nombre de jugador, bolsa y caja para el equipo.`,
    imgCart: camisetafemenina1,
  },
  {
    id: 5,
    title: "CAMISETA NIÑO",
    price: "3.499",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 3499,
    imgtop: '../imagenes/productosIndividuales/productoIndividual5.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Ideal para los chicos, escuelitas, clubes que tengan categorías inferiores o simplemente un grupo de chicos que quiera jugar al fútbol.
    Este producto trabajado en tela Set Poliéster o Cooldry, se sublima con un método de calandrado para la impresión en los colores más vivos posibles.
    `,
    imgCart: camisetanio1,
  },
  {
    id: 6,
    title: "CAMISETA SUBLIMADA",
    price: "3.999",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 3999,
    imgtop: '../imagenes/bloobWelcome01.jpg',
    imgdownleft: './images/item-4.jpeg',
    imgdownright:'./images/item-4.jpeg',
    desc: `Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles.
    `,
    imgCart: camisetasublimada1,
  },
  {
    id: 7,
    title: "CONJUNTOS",
    price: "5.199",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 5199,
    imgtop: "../imagenes/productosIndividuales/productoIndividual8.png",
    imgdownleft:"../imagenes/productosIndividuales/productoIndividual1.png",
    imgdownright:"./images/item-4.jpeg",
    desc: `Con un formato de camiseta + short este conjunto te deja listo para salir a la cancha.
    Este producto trabajado en tela Set Poliéster o Cooldry,
    se sublima con un método de calandrado para la
    impresión en los colores más vivos posibles.`,
    imgCart: conjuntos1,
  },
  {
    id: 8,
    title: "SUDADERA",
    price: "3.099",
    pricea: "2.000",
    priceb: "1.900",
    priceCart: 3099,
    imgtop: '..src/imagenes/productosIndividuales/productoIndividual5.png',
    imgdownleft:"./images/item-4.jpeg",
    imgdownright:"./images/item-4.jpeg",
    desc: `Para adelantarse al verano, las sudaderas son ideales para mayor comodidad. Combati el calor en tus entrenamientos, gimnasio o en casa con nuestras sudaderas en tela deportiva.
    Este producto trabajado en tela Set Poliéster o Cooldry, nuestras telas premium trabajadas con impresion profesional para acabados de alta calidad.
    - 100% personalizadas. - Tela ideal para los momentos de transpiración, con protección antibacterial, termorregulación y secado rápido. - Moldería masculina y femenina. - Packaging con nombre de jugador, bolsa y caja para el equipo.`,
    imgCart: sudadera1,
  },
];
export default catalogo;
