import React, {useState} from "react";
import bannerProductos from "../imagenes/bannerProductos.jpeg";
import bannerBloob from "../imagenes/PAGINA WEB JB/banner-bloob.jpg"
import CuadriculaProductos from "./CuadriculaProductos";
import catalogoLista from '../informacion/catalogo';
import { motion } from "framer-motion";

//imagenes productos

import producto1 from "../imagenes/productosIndividuales/productoIndividual4.png";
import producto2 from "../imagenes/productosIndividuales/productoIndividual5.png";
import producto3 from "../imagenes/productosIndividuales/productoIndividual6.png";
import producto4 from "../imagenes/productosIndividuales/productoIndividual7.png";

function ProductosBloob() {
  const [catalogoItems, setCatalogoItems] = useState(catalogoLista);
  
  return (
    <motion.div
    initial={{ opacity: 0 }}
    transition={{ delay: 0.3 }}
    whileInView={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="cuadricula-superior">
      <br></br>
      <br></br>
      <br></br>
      <div className="bannerImageDiv">
        <img className="bannerImagePic" src={bannerBloob} />
      </div>
      <div>
        <CuadriculaProductos catalogoLista={catalogoItems}/>
      </div>

    </motion.div>
  );
}

export default ProductosBloob;