import React from "react";
import bloobGifEstirado from "../imagenes/bloobGifEstirado.gif";
import video1 from "../imagenes/Bloobvideo.mp4";
import video2 from "../imagenes/DropPaginaWeb.mp4"
{
  //import videoDrop from '../imagenes/Bloobvideo1.mp4'
}

function rightVideo(video) {
  if (video == "videoBloob") {
    return (
      <>      
        <video autoPlay loop className="back-video" muted playsInline>
          <source src={video1} type="video/mp4" />
        </video>
      </>
    );
  } else if(video =="videoDrop"){
    return (
      <>
        <video autoPlay loop className="back-video" muted playsInline>
          <source src={video2} type="video/mp4" />
        </video>
      </>
    );
  }
}

function Video(props) {
  return (
    <div className="container-video">
      <div className="video">
        {rightVideo(props.video)}
        
      </div>
    </div>
  );
}

export default Video;
