import React, { useContext, useState, useRef } from "react";
import "../styles/verproducto.css";
import catalogo from "../informacion/catalogo";
import { useParams } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { motion, useDomEvent } from "framer-motion";

import { BsTruck } from "react-icons/bs";
import { FaMedal } from "react-icons/fa";
import { BsWind } from "react-icons/bs";
import { AiFillClockCircle } from "react-icons/ai";
import { BsCart3 } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";

import { Link } from "react-router-dom";
import camisetaSeccion3 from "../imagenes/camisetaSeccion3.jpg";
import bloobWelcome01 from "../imagenes/bloobWelcome01.jpg";

//prod1
import producto1Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-1.jpg";
import producto2Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-2.jpg";
import producto3Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-3.jpg";
//prod2
import camisetabordada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada1.jpg";
import camisetabordada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada2.jpg";
import camisetabordada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada3.jpg";
//prod3
import camisetabordadayvinilada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada1.jpg";
import camisetabordadayvinilada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada2.jpg";
import camisetabordadayvinilada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada3.jpg";
//prod4
import camisetafemenina1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina1.jpg";
import camisetafemenina2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina2.jpg";
import camisetafemenina3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina3.jpg";
//prod5
import camisetanio1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio1.jpg";
import camisetanio2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio2.jpg";
import camisetanio3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio3.jpg";
//prod6
import camisetasublimada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada1.jpg";
import camisetasublimada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada2.jpg";
import camisetasublimada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada3.jpg";
//prod7
import conjuntos1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos1.jpg";
import conjuntos2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos2.jpg";
import conjuntos3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos3.jpg";
//prod8
import sudadera1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera1.jpg";
import sudadera2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera2.jpg";

import sudadera3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera3.jpg";

//shop cart
import { ShopContext } from "./Shop-context";

const transition = {
  type: "spring",
  damping: 20,
  stiffness: 120,
};

function RightPicture(
  id,
  isOpen,
  setOpen,
  isOpen1,
  setOpen1,
  isOpen2,
  setOpen2
) {
  if (id == 1) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ type: "spring", damping: 50, stiffness: 120 }}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            className="imagensuperior"
            src={producto1Baseball}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={transition}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={producto2Baseball}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={producto3Baseball}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 2) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ type: "spring", damping: 50, stiffness: 120 }}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            className="imagensuperior"
            src={camisetabordada1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={transition}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={camisetabordada2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={camisetabordada3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 3) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ type: "spring", damping: 50, stiffness: 120 }}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            className="imagensuperior"
            src={camisetabordadayvinilada1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={{ type: "spring", damping: 50, stiffness: 120 }}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={camisetabordadayvinilada2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={camisetabordadayvinilada3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 4) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ type: "spring", damping: 50, stiffness: 120 }}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            className="imagensuperior"
            src={camisetafemenina1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={{ type: "spring", damping: 50, stiffness: 120 }}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={camisetafemenina2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={camisetafemenina3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 5) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ type: "spring", damping: 50, stiffness: 120 }}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            className="imagensuperior"
            src={camisetanio1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={{ type: "spring", damping: 50, stiffness: 120 }}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={camisetanio2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={camisetanio3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 6) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={transition}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            src={camisetasublimada1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={transition}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={camisetasublimada2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={camisetasublimada3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 7) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={transition}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            src={conjuntos1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={transition}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={conjuntos2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={conjuntos3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  } else if (id == 8) {
    return (
      <div className="container-imagenes">
        <div className={`imagen-superior-container ${isOpen ? "open" : ""}`}>
          <motion.div
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={transition}
            className="shade"
            onClick={() => setOpen(false)}
          />
          <motion.img
            src={sudadera1}
            onClick={() => setOpen(!isOpen)}
            layoutTransition={transition}
          />
        </div>
        <div className="imagenes-inferiores">
          <div className={`imagen-izquierda ${isOpen1 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen1 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen1(false)}
            />
            <motion.img
              src={sudadera2}
              onClick={() => setOpen1(!isOpen1)}
              className="imagen-inferior-izquierda"
              layoutTransition={transition}
            />
          </div>
          <div className={`imagen-derecha ${isOpen2 ? "open" : ""}`}>
            <motion.div
              animate={{ opacity: isOpen2 ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpen2(false)}
            />
            <motion.img
              src={sudadera3}
              onClick={() => setOpen2(!isOpen2)}
              className="imagen-inferior-derecha"
              layoutTransition={transition}
            />
          </div>
        </div>
      </div>
    );
  }
}

function VerProducto() {
  const navigate = useNavigate();
  const { id } = useParams();
  const catalogoItem = catalogo[id - 1];
  const { addToCart, cartItems } = useContext(ShopContext);
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);

  {
    //  useDomEvent(useRef(window), "scroll", () => isOpen && setOpen(false));
  }
  const cartItemAmount = cartItems[id];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="todo-en-verproducto"
    >
      <div className="division-izquierda-derecha">
        <div className="imagenes-izquierda">
          {/*
           */}
          {RightPicture(
            id,
            isOpen,
            setOpen,
            isOpen1,
            setOpen1,
            isOpen2,
            setOpen2
          )}
        </div>
        <div className="informacion-derecha">
          <div className="titulo-camiseta-aca">
            <h2 className="titulo-principal-de-producto">
              {catalogoItem.title}
            </h2>
            <h2 className="descripcion-producto">{catalogoItem.desc}</h2>
          </div>
          <div className="precios"></div>
          <div className="solicita-tu-disenio-button">
            <a
              href="https://wa.me/+5492214097465"
              target="_blank"
              className="texto-disenio"
            >
              <button className="solicita-disenio">
                {" "}
                <b className="boton-text-prod-ind-solicita">
                  SOLICITÁ TU DISEÑO{" "}
                </b>{" "}
                <BsWhatsapp className="whatsapp-loguito" size={20} />
              </button>
            </a>
          </div>
          {/*<h3 className="precio-text-prod-ind">
            A PARTIR DE ${catalogoItem.price}
          </h3>*/}
          <div className="senia">
            <div className="imagen-peso"></div>
            <div className="senia-derecha-texto">
              <div>
                <h1 className="senia-texto">Seña del 60% para comenzar</h1>
              </div>
            </div>
          </div>
          <div className="envio-y-bandera">
            <h3 className="verde">Envío gratuito a partir de 20 unidades</h3>
            <h3 className="verde">Bandera de regalo a partir de 50 unidades</h3>
          </div>
          <div className="demora-efectivo">
            <h2 className="demora-texto">
              {" "}
              <AiFillClockCircle size={20} /> DEMORA 30 - 35 DÍAS HÁBILES
            </h2>
            <h3 className="promociones-texto">
              <i>Promociones pagando en efectivo</i>{" "}
            </h3>
          </div>

          {/*
       <div className="boton-agregar-carrito">
            <button
              className="boton-text-prod-ind"
              onClick={() => addToCart(catalogoItem.id)}
            >
              <BsCart3 size={20}/>

              Agregar al carrito{" "}

              {cartItemAmount > 0 && <> ({cartItemAmount})</>}
            </button>
          </div>
*/}
          <div></div>
        </div>
      </div>

      <div className="footer-marketing">
        <div className="tela">
          <div className="tela-icon">
            <BsWind size={40} />
          </div>
          <h2 className="bottom-text-box">TELA DE PRIMER NIVEL</h2>
        </div>
        <div className="garantia">
          <div className="garantia-icon">
            <FaMedal size={40} />
          </div>
          <h2 className="bottom-text-box"> UN MES DE GARANTÍA</h2>
        </div>
        <div className="envios">
          <div className="camionsito">
            <BsTruck size={40} />
          </div>
          <h2 className="bottom-text-box"> ENVÍOS A TODO EL PAÍS</h2>
        </div>
      </div>
    </motion.div>
  );
}

export default VerProducto;
