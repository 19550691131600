import React from "react";
import DropdownProducto from "./DropdownProducto";
import { useState, useEffect } from "react";
import DropdownBordado from "./DropdownBordado ";
import DropdownTela from "./DropdownTela";
import "../informacion/precios.js";
import { BsCalculator } from "react-icons/bs";
import { IconContext } from "react-icons";

import "../styles/calculadordeprecios.css";
import precios from "../informacion/precios.js";
import Whatsappbutton from "./Whatsappbutton";

import CustomDay from "./CustomDay";


function CalculadorDePrecios() {
  const [porPersonaEfectivo, setPorPersonaEfectivo] = useState("");

  const [porPersonaTransferencia, setPorPersonaTransferencia] = useState("");

  const [personas, setPersonas] = useState("  ");
  //row 1
  const [selected, setSelected] = useState("");

  const [bordaded, setBordaded] = useState("");

  const [tela, setTela] = useState("");

  const [cantidad, setCantidad] = useState("");

  const [cantidadIngresada, setCantidadIngresada] = useState(cantidad);

  const handleChange = (event) => {
    setCantidad(event.target.value);
    console.log("cantidad handlechange");
    console.log(cantidad);
  };
  // row 2
  const [selected1, setSelected1] = useState("");

  const [bordaded1, setBordaded1] = useState("");

  const [tela1, setTela1] = useState("");

  const [cantidad1, setCantidad1] = useState("");

  const [cantidadIngresada1, setCantidadIngresada1] = useState(cantidad1);

  const handleChange1 = (event) => {
    setCantidad1(event.target.value);
  };

  //

  // row 3

  const [selected2, setSelected2] = useState("");

  const [bordaded2, setBordaded2] = useState("");

  const [tela2, setTela2] = useState("");

  const [cantidad2, setCantidad2] = useState("");

  const [cantidadIngresada2, setCantidadIngresada2] = useState(cantidad1);

  const handleChange2 = (event) => {
    setCantidad2(event.target.value);
  };

  //

  //calcular por persona
  const handleChangePersona = (event) => {
    setPersonas(event.target.value);
  };

  //handleClickPersona
  const handleClickPersona = () => {
    setPorPersonaTransferencia(
      (precioFinaltransferencia +
        precioFinaltransferencia1 +
        precioFinaltransferencia2) /
        personas
    );

    setPorPersonaEfectivo(
      (precioFinalEfectivo + precioFinalEfectivo1 + precioFinalEfectivo2) /
        personas
    );
  };
  //row 1
  const [tempPriceEfectivo, setTempPriceEfectivo] = useState(0);
  const [tempPriceTransferencia, setTempPriceTransferencia] = useState(0);
  const [precioFinalEfectivo, setPreciofinalefectivo] = useState(0);
  const [precioFinaltransferencia, setPreciofinaltransferencia] = useState(0);

  //row 2

  const [tempPriceEfectivo1, setTempPriceEfectivo1] = useState(0);
  const [tempPriceTransferencia1, setTempPriceTransferencia1] = useState(0);
  const [precioFinalEfectivo1, setPreciofinalefectivo1] = useState(0);
  const [precioFinaltransferencia1, setPreciofinaltransferencia1] = useState(0);

  //row 3

  const [tempPriceEfectivo2, setTempPriceEfectivo2] = useState(0);
  const [tempPriceTransferencia2, setTempPriceTransferencia2] = useState(0);
  const [precioFinalEfectivo2, setPreciofinalefectivo2] = useState(0);
  const [precioFinaltransferencia2, setPreciofinaltransferencia2] = useState(0);

  //precios
  var camisetaGrupo1Efectivo = 3099;
  var camisetaGrupo1Transferencia = 3499;
  var camisetaGrupo2Efectivo = 2899;
  var camisetaGrupo2Transferencia = 3299;
  var camisetaGrupo3Efectivo = 2699;
  var camisetaGrupo3Transferencia = 3099;

  var conjuntoGrupo1Efectivo = 4899;
  var conjuntoGrupo1Transferencia = 5299;
  var conjuntoGrupo2Efectivo = 4699;
  var conjuntoGrupo2Transferencia = 5099;
  var conjuntoGrupo3Efectivo = 4499;
  var conjuntoGrupo3Transferencia = 4899;

  var chombasGrupo1Efectivo = 3599;
  var chombasGrupo1Transferencia = 3999;
  var chombasGrupo2Efectivo = 3399;
  var chombasGrupo2Transferencia = 3799;
  var chombasGrupo3Efectivo = 3199;
  var chombasGrupo3Transferencia = 3599;

  var musculosaGrupo1Efectivo = 2899;
  var musculosaGrupo1Transferencia = 3299;
  var musculosaGrupo2Efectivo = 4699;
  var musculosaGrupo2Transferencia = 2699;
  var musculosaGrupo3Efectivo = 4499;
  var musculosaGrupo3Transferencia = 2499;

  var shortGrupo1Efectivo = 2799;
  var shortGrupo1Transferencia = 3199;
  var shortGrupo2Efectivo = 2499;
  var shortGrupo2Transferencia = 2999;
  var shortGrupo3Efectivo = 2299;
  var shortGrupo3Transferencia = 2799;

  var conjuntoInviernoGrupo1Efectivo = 18999;
  var conjuntoInviernoGrupo1Transferencia = 19999;
  var conjuntoInviernoGrupo2Efectivo = 16999;
  var conjuntoInviernoGrupo2Transferencia = 17999;
  var conjuntoInviernoGrupo3Efectivo = 14999;
  var conjuntoInviernoGrupo3Transferencia = 15999;

  var amount =
    precioFinaltransferencia +
    precioFinaltransferencia1 +
    precioFinaltransferencia2;
  //////////////////////////////////////////
  //          USE EFFECTS PARA ROW 1      //
  //////////////////////////////////////////
  // fuera del handleClick un useEffect para actualizar los valores de temp antes de correr el codigo 2 veces

  useEffect(() => {
    if (selected === "Camiseta" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * camisetaGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * camisetaGrupo1Transferencia);
    } else if (selected === "Camiseta" && cantidad >= 10 && cantidad <= 50) {
      setTempPriceEfectivo(cantidad * camisetaGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * camisetaGrupo2Transferencia);
    } else if (selected === "Camiseta" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * camisetaGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * camisetaGrupo3Transferencia);
    }
  }, [selected, cantidad]);
  // Use Effect para conjuntos
  useEffect(() => {
    if (selected === "Conjunto" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * conjuntoGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoGrupo1Transferencia);
    } else if (selected === "Conjunto" && cantidad >= 10 && cantidad <= 50) {
      setTempPriceEfectivo(cantidad * conjuntoGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoGrupo2Transferencia);
    } else if (selected === "Conjunto" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * conjuntoGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoGrupo3Transferencia);
    }
  }, [selected, cantidad]);
  // UseEffect para chombas
  useEffect(() => {
    if (selected === "Chombas" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * chombasGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * chombasGrupo1Transferencia);
    } else if (selected === "Chombas" && cantidad >= 10 && cantidad <= 50) {
      setTempPriceEfectivo(cantidad * chombasGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * chombasGrupo2Transferencia);
    } else if (selected === "Chombas" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * chombasGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * chombasGrupo3Transferencia);
    }
  }, [selected, cantidad]);
  // UseEffect musculosa

  useEffect(() => {
    if (selected === "Musculosa" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * musculosaGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * musculosaGrupo1Transferencia);
    } else if (selected === "Musculosa" && cantidad >= 10 && cantidad <= 50) {
      setTempPriceEfectivo(cantidad * musculosaGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * musculosaGrupo2Transferencia);
    } else if (selected === "Musculosa" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * musculosaGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * musculosaGrupo3Transferencia);
    }
  }, [selected, cantidad]);

  // UseEffect short

  useEffect(() => {
    if (selected === "Short" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * shortGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * shortGrupo1Transferencia);
    } else if (selected === "Short" && cantidad >= 10 && cantidad <= 50) {
      setTempPriceEfectivo(cantidad * shortGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * shortGrupo2Transferencia);
    } else if (selected === "Short" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * shortGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * shortGrupo3Transferencia);
    }
  }, [selected, cantidad]);

  // UseEffect conjunto invierno

  useEffect(() => {
    if (selected === "Conjunto Invierno" && cantidad >= 5 && cantidad <= 9) {
      setTempPriceEfectivo(cantidad * conjuntoInviernoGrupo1Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoInviernoGrupo1Transferencia);
    } else if (
      selected === "Conjunto Invierno" &&
      cantidad >= 10 &&
      cantidad <= 50
    ) {
      setTempPriceEfectivo(cantidad * conjuntoInviernoGrupo2Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoInviernoGrupo2Transferencia);
    } else if (selected === "Conjunto Invierno" && cantidad >= 50) {
      setTempPriceEfectivo(cantidad * conjuntoInviernoGrupo3Efectivo);
      setTempPriceTransferencia(cantidad * conjuntoInviernoGrupo3Transferencia);
    }
  }, [selected, cantidad]);
  //////

  //////////////////////////////////////////
  //          USE EFFECTS PARA ROW 2      //
  //////////////////////////////////////////
  // fuera del handleClick un useEffect para actualizar los valores de temp antes de correr el codigo 2 veces
  useEffect(() => {
    if (selected1 === "Camiseta" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * camisetaGrupo1Efectivo);
      setTempPriceTransferencia1(cantidad1 * camisetaGrupo1Transferencia);
    } else if (selected1 === "Camiseta" && cantidad1 >= 10 && cantidad1 <= 50) {
      setTempPriceEfectivo1(cantidad1 * camisetaGrupo2Efectivo);
      setTempPriceTransferencia1(cantidad1 * camisetaGrupo2Transferencia);
    } else if (selected1 === "Camiseta" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * camisetaGrupo3Efectivo);
      setTempPriceTransferencia1(cantidad1 * camisetaGrupo3Transferencia);
    }
  }, [selected1, cantidad1]);
  // Use Effect para conjuntos
  useEffect(() => {
    if (selected1 === "Conjunto" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * conjuntoGrupo1Efectivo);
      setTempPriceTransferencia1(cantidad1 * conjuntoGrupo1Transferencia);
    } else if (selected1 === "Conjunto" && cantidad1 >= 10 && cantidad1 <= 50) {
      setTempPriceEfectivo1(cantidad1 * conjuntoGrupo2Efectivo);
      setTempPriceTransferencia1(cantidad1 * conjuntoGrupo2Transferencia);
    } else if (selected1 === "Conjunto" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * conjuntoGrupo3Efectivo);
      setTempPriceTransferencia1(cantidad1 * conjuntoGrupo3Transferencia);
    }
  }, [selected1, cantidad1]);
  // UseEffect para chombas
  useEffect(() => {
    if (selected1 === "Chombas" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * chombasGrupo1Efectivo);
      setTempPriceTransferencia1(cantidad1 * chombasGrupo1Transferencia);
    } else if (selected1 === "Chombas" && cantidad1 >= 10 && cantidad1 <= 50) {
      setTempPriceEfectivo1(cantidad1 * chombasGrupo2Efectivo);
      setTempPriceTransferencia1(cantidad1 * chombasGrupo2Transferencia);
    } else if (selected1 === "Chombas" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * chombasGrupo3Efectivo);
      setTempPriceTransferencia1(cantidad1 * chombasGrupo3Transferencia);
    }
  }, [selected1, cantidad1]);
  // UseEffect musculosa

  useEffect(() => {
    if (selected1 === "Musculosa" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * musculosaGrupo1Efectivo);
      setTempPriceTransferencia1(cantidad1 * musculosaGrupo1Transferencia);
    } else if (
      selected1 === "Musculosa" &&
      cantidad1 >= 10 &&
      cantidad1 <= 50
    ) {
      setTempPriceEfectivo1(cantidad1 * musculosaGrupo2Efectivo);
      setTempPriceTransferencia1(cantidad1 * musculosaGrupo2Transferencia);
    } else if (selected1 === "Musculosa" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * musculosaGrupo3Efectivo);
      setTempPriceTransferencia1(cantidad1 * musculosaGrupo3Transferencia);
    }
  }, [selected1, cantidad1]);

  // UseEffect short

  useEffect(() => {
    if (selected1 === "Short" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * shortGrupo1Efectivo);
      setTempPriceTransferencia1(cantidad1 * shortGrupo1Transferencia);
    } else if (selected1 === "Short" && cantidad1 >= 10 && cantidad1 <= 50) {
      setTempPriceEfectivo1(cantidad1 * shortGrupo2Efectivo);
      setTempPriceTransferencia1(cantidad1 * shortGrupo2Transferencia);
    } else if (selected1 === "Short" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * shortGrupo3Efectivo);
      setTempPriceTransferencia1(cantidad1 * shortGrupo3Transferencia);
    }
  }, [selected1, cantidad1]);

  // UseEffect conjunto invierno

  useEffect(() => {
    if (selected1 === "Conjunto Invierno" && cantidad1 >= 5 && cantidad1 <= 9) {
      setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo1Efectivo);
      setTempPriceTransferencia1(
        cantidad1 * conjuntoInviernoGrupo1Transferencia
      );
    } else if (
      selected1 === "Conjunto Invierno" &&
      cantidad1 >= 10 &&
      cantidad1 <= 50
    ) {
      setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo2Efectivo);
      setTempPriceTransferencia1(
        cantidad1 * conjuntoInviernoGrupo2Transferencia
      );
    } else if (selected1 === "Conjunto Invierno" && cantidad1 >= 50) {
      setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo3Efectivo);
      setTempPriceTransferencia1(
        cantidad1 * conjuntoInviernoGrupo3Transferencia
      );
    }
  }, [selected1, cantidad1]);

  //////

  //////////////////////////////////////////
  //          USE EFFECTS PARA ROW 3      //
  //////////////////////////////////////////
  // fuera del handleClick un useEffect para actualizar los valores de temp antes de correr el codigo 2 veces
  useEffect(() => {
    if (selected2 === "Camiseta" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * camisetaGrupo1Efectivo);
      setTempPriceTransferencia2(cantidad2 * camisetaGrupo1Transferencia);
    } else if (selected2 === "Camiseta" && cantidad2 >= 10 && cantidad2 <= 50) {
      setTempPriceEfectivo2(cantidad2 * camisetaGrupo2Efectivo);
      setTempPriceTransferencia2(cantidad2 * camisetaGrupo2Transferencia);
    } else if (selected2 === "Camiseta" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * camisetaGrupo3Efectivo);
      setTempPriceTransferencia2(cantidad2 * camisetaGrupo3Transferencia);
    }
  }, [selected2, cantidad2]);
  // Use Effect para conjuntos
  useEffect(() => {
    if (selected2 === "Conjunto" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * conjuntoGrupo1Efectivo);
      setTempPriceTransferencia2(cantidad2 * conjuntoGrupo1Transferencia);
    } else if (selected2 === "Conjunto" && cantidad2 >= 10 && cantidad2 <= 50) {
      setTempPriceEfectivo2(cantidad2 * conjuntoGrupo2Efectivo);
      setTempPriceTransferencia2(cantidad2 * conjuntoGrupo2Transferencia);
    } else if (selected2 === "Conjunto" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * conjuntoGrupo3Efectivo);
      setTempPriceTransferencia2(cantidad2 * conjuntoGrupo3Transferencia);
    }
  }, [selected2, cantidad2]);
  // UseEffect para chombas
  useEffect(() => {
    if (selected2 === "Chombas" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * chombasGrupo1Efectivo);
      setTempPriceTransferencia2(cantidad2 * chombasGrupo1Transferencia);
    } else if (selected2 === "Chombas" && cantidad2 >= 10 && cantidad2 <= 50) {
      setTempPriceEfectivo2(cantidad2 * chombasGrupo2Efectivo);
      setTempPriceTransferencia2(cantidad2 * chombasGrupo2Transferencia);
    } else if (selected2 === "Chombas" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * chombasGrupo3Efectivo);
      setTempPriceTransferencia2(cantidad2 * chombasGrupo3Transferencia);
    }
  }, [selected2, cantidad2]);
  // UseEffect musculosa

  useEffect(() => {
    if (selected2 === "Musculosa" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * musculosaGrupo1Efectivo);
      setTempPriceTransferencia2(cantidad2 * musculosaGrupo1Transferencia);
    } else if (
      selected2 === "Musculosa" &&
      cantidad2 >= 10 &&
      cantidad2 <= 50
    ) {
      setTempPriceEfectivo2(cantidad2 * musculosaGrupo2Efectivo);
      setTempPriceTransferencia2(cantidad2 * musculosaGrupo2Transferencia);
    } else if (selected2 === "Musculosa" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * musculosaGrupo3Efectivo);
      setTempPriceTransferencia2(cantidad2 * musculosaGrupo3Transferencia);
    }
  }, [selected2, cantidad2]);

  // UseEffect short

  useEffect(() => {
    if (selected2 === "Short" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * shortGrupo1Efectivo);
      setTempPriceTransferencia2(cantidad2 * shortGrupo1Transferencia);
    } else if (selected2 === "Short" && cantidad2 >= 10 && cantidad2 <= 50) {
      setTempPriceEfectivo2(cantidad2 * shortGrupo2Efectivo);
      setTempPriceTransferencia2(cantidad2 * shortGrupo2Transferencia);
    } else if (selected2 === "Short" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * shortGrupo3Efectivo);
      setTempPriceTransferencia2(cantidad2 * shortGrupo3Transferencia);
    }
  }, [selected2, cantidad2]);

  // UseEffect conjunto invierno

  useEffect(() => {
    if (selected2 === "Conjunto Invierno" && cantidad2 >= 5 && cantidad2 <= 9) {
      setTempPriceEfectivo2(cantidad2 * conjuntoInviernoGrupo1Efectivo);
      setTempPriceTransferencia2(
        cantidad2 * conjuntoInviernoGrupo1Transferencia
      );
    } else if (
      selected2 === "Conjunto Invierno" &&
      cantidad2 >= 10 &&
      cantidad2 <= 50
    ) {
      setTempPriceEfectivo2(cantidad2 * conjuntoInviernoGrupo2Efectivo);
      setTempPriceTransferencia2(
        cantidad2 * conjuntoInviernoGrupo2Transferencia
      );
    } else if (selected2 === "Conjunto Invierno" && cantidad2 >= 50) {
      setTempPriceEfectivo2(cantidad2 * conjuntoInviernoGrupo3Efectivo);
      setTempPriceTransferencia2(
        cantidad2 * conjuntoInviernoGrupo3Transferencia
      );
    }
  }, [selected2, cantidad2]);

  //row 1
  const handleClick = () => {
    if (selected == "Camiseta") {
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }

      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 1000);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
    }

    // conjuntos
    if (
      selected == "Conjunto" ||
      (selected == "Conjunto" && tela == "Poliester")
    ) {
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 900);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 900);
      }
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 1000);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }

      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 1000);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
    }

    // chombas
    if (
      selected == "Chombas" ||
      (selected == "Chombas" && tela == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 1000);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
      // solo cooldry
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      // sin nada
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }
      // bobrdado & cooldry
      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 900);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
    }

    // musculosa
    if (
      selected == "Musculosa" ||
      (selected == "Musculosa" && tela == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      // solo cooldry
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 400);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 400);
      }
      // sin nada
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }
      // bobrdado & cooldry
      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 900);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 900);
      }
    }

    // short
    if (selected == "Short" || (selected == "Short" && tela == "Poliester")) {
      // solo bordado
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      // solo cooldry
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 500);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 500);
      }
      // sin nada
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }
      // bobrdado & cooldry
      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 1000);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 1000);
      }
    }

    // conjunto invierno
    if (
      selected == "Conjunto Invierno" ||
      (selected == "Conjunto Invierno" && tela == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded == "Bordado" &&
        (tela !== "Cooldry" || tela == "Poliester")
      ) {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 900);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 900);
      }
      // solo cooldry
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Cooldry"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }
      // sin nada
      if (
        (bordaded == "Sin Bordado" || bordaded == "Bordado?") &&
        tela == "Poliester"
      ) {
        setPreciofinalefectivo(tempPriceEfectivo);
        setPreciofinaltransferencia(tempPriceTransferencia);
      }
      // bobrdado & cooldry
      if (bordaded == "Bordado" && tela == "Cooldry") {
        setPreciofinalefectivo(tempPriceEfectivo + cantidad * 900);
        setPreciofinaltransferencia(tempPriceTransferencia + cantidad * 900);
      }
    }
  };
  // row 2
  const handleClick1 = () => {
    if (
      selected1 == "Camiseta" ||
      (selected1 == "Camiseta" && tela1 == "Poliester")
    ) {
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      if (

        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }

      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 1000);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
    }

    // conjuntos
    if (
      selected1 == "Conjunto" ||
      (selected1 == "Conjunto" && tela1 == "Poliester")
    ) {
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 900);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 900);
      }
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 1000);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }

      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 1000);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
    }

    // chombas
    if (
      selected1 == "Chombas" ||
      (selected1 == "Chombas" && tela1 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 1000);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
      // solo cooldry
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      // sin nada
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }
      // bobrdado & cooldry
      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 900);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
    }

    // musculosa
    if (
      selected1 == "Musculosa" ||
      (selected1 == "Musculosa" && tela1 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      // solo cooldry
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 400);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 400);
      }
      // sin nada
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }
      // bobrdado & cooldry
      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 900);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 900);
      }
    }

    // short
    if (
      selected1 == "Short" ||
      (selected1 == "Short" && tela1 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      // solo cooldry
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 500);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 500);
      }
      // sin nada
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }
      // bobrdado & cooldry
      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 1000);
        setPreciofinaltransferencia1(
          tempPriceTransferencia1 + cantidad1 * 1000
        );
      }
    }

    // conjunto invierno
    if (
      selected1 == "Conjunto Invierno" ||
      (selected1 == "Conjunto Invierno" && tela1 == "Poliester")
    ) {
      if (cantidad1 >= 5 && cantidad1 <= 9) {
        setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo1Efectivo);
        setTempPriceTransferencia1(
          cantidad1 * conjuntoInviernoGrupo1Transferencia
        );
      } else if (cantidad1 >= 10 && cantidad1 < 50) {
        setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo2Efectivo);
        setTempPriceTransferencia1(
          cantidad1 * conjuntoInviernoGrupo2Transferencia
        );
      } else if (cantidad1 >= 50) {
        setTempPriceEfectivo1(cantidad1 * conjuntoInviernoGrupo3Efectivo);
        setTempPriceTransferencia1(
          cantidad1 * conjuntoInviernoGrupo3Transferencia
        );
      }
      // solo bordado
      if (
        bordaded1 == "Bordado" &&
        (tela1 !== "Cooldry" || tela1 == "Poliester")
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 900);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 900);
      }
      // solo cooldry
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Cooldry"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }
      // sin nada
      if (
        (bordaded1 == "Sin Bordado" || bordaded1 == "Bordado?") &&
        tela1 == "Poliester"
      ) {
        setPreciofinalefectivo1(tempPriceEfectivo1);
        setPreciofinaltransferencia1(tempPriceTransferencia1);
      }
      // bobrdado & cooldry
      if (bordaded1 == "Bordado" && tela1 == "Cooldry") {
        setPreciofinalefectivo1(tempPriceEfectivo1 + cantidad1 * 900);
        setPreciofinaltransferencia1(tempPriceTransferencia1 + cantidad1 * 900);
      }
    }

    //
  };

  // row 3
  const handleClick2 = () => {
    if (
      selected2 == "Camiseta" ||
      (selected2 == "Camiseta" && tela2 == "Poliester")
    ) {
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }

      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 1000);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
    }

    // conjuntos
    if (
      selected2 == "Conjunto" ||
      (selected2 == "Conjunto" && tela2 == "Poliester")
    ) {
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 900);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 900);
      }
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 1000);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }

      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 1000);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
    }

    // chombas
    if (
      selected2 == "Chombas" ||
      (selected2 == "Chombas" && tela2 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 1000);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
      // solo cooldry
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      // sin nada
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }
      // bobrdado & cooldry
      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 900);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
    }

    // musculosa
    if (
      selected2 == "Musculosa" ||
      (selected2 == "Musculosa" && tela2 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      // solo cooldry
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 400);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 400);
      }
      // sin nada
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }
      // bobrdado & cooldry
      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 900);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 900);
      }
    }

    // short
    if (
      selected2 == "Short" ||
      (selected2 == "Short" && tela2 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      // solo cooldry
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 500);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 500);
      }
      // sin nada
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }
      // bobrdado & cooldry
      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 1000);
        setPreciofinaltransferencia2(
          tempPriceTransferencia2 + cantidad2 * 1000
        );
      }
    }

    // conjunto invierno
    if (
      selected2 == "Conjunto Invierno" ||
      (selected2 == "Short" && tela2 == "Poliester")
    ) {
      // solo bordado
      if (
        bordaded2 == "Bordado" &&
        (tela2 !== "Cooldry" || tela2 == "Poliester")
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 900);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 900);
      }
      // solo cooldry
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Cooldry"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }
      // sin nada
      if (
        (bordaded2 == "Sin Bordado" || bordaded2 == "Bordado?") &&
        tela2 == "Poliester"
      ) {
        setPreciofinalefectivo2(tempPriceEfectivo2);
        setPreciofinaltransferencia2(tempPriceTransferencia2);
      }
      // bobrdado & cooldry
      if (bordaded2 == "Bordado" && tela2 == "Cooldry") {
        setPreciofinalefectivo2(tempPriceEfectivo2 + cantidad2 * 900);
        setPreciofinaltransferencia2(tempPriceTransferencia2 + cantidad2 * 900);
      }
    }
  };

  /*
  // Agrega credenciales de SDK

const mp = new MercadoPago("APP_USR-264c416a-acc7-44bf-9e5a-2f0b759d66ee", {
  locale: "es-AR",
});


// Inicializa el checkout
mp.checkout({
preference: {
id: "821258844-27e6b7d3-04cd-427f-8285-04692ac96bee",
},
render: {
container: ".pagar", // Indica el nombre de la clase donde se mostrará el botón de pago
label: "Pago", // Cambia el texto del botón de pago (opcional)
},
})
        */

  var url =
    "https://api.whatsapp.com/send?phone=5492214097465&text=Hola!%20Hice%20mi%20pedido%20y%20presupuesto online!%0a---Informacion---%0a-Producto: " +
    selected +
    "%0a-Bordado: " +
    bordaded +
    "%0a-Tela: " +
    tela +
    "%0a-Cantidad: " +
    cantidad +
    "%0a-Precio Efectivo: " +
    precioFinalEfectivo +
    "%0a-Precio Transferencia: " +
    precioFinaltransferencia;
  return (
    <div className="container-calculador">
      <div className="presupuestos-titulo">
        <h1 className="h1-texto">PRESUPUESTOS</h1>
      </div>
      <div className="info-and-row-containers">
        <div className="info-superior">
          <h1 className="carrito-distancia size-text">CARRITO</h1>
          <h1 className="productos-distancia size-text">PRODUCTOS</h1>
          <h1 className="extras-distancia size-text">EXTRAS</h1>
          <h1 className="telas-distancia size-text">TELAS</h1>
          <h1 className="unidades-distancia size-text">UNIDADES</h1>
          <h1 className="efectivo-distancia size-text">EFECTIVO</h1>
          <h1 className="transferencia-distancia size-text">TRANSFERENCIA</h1>
        </div>
        <div className="row-containers">
          <div className="row">
            <h2 className="mini-titulo-producto">Producto 1</h2>
            <DropdownProducto selected={selected} setSelected={setSelected} />
            <DropdownBordado bordaded={bordaded} setBordaded={setBordaded} />
            <DropdownTela tela={tela} setTela={setTela} />
            <input
              className="cantidad-cajita"
              type="text"
              placeholder="Cantidad de unidades"
              id="cantidad"
              onChange={handleChange}
              value={cantidad}
            />
            <div onClick={handleClick} className="boton-container">
              <button onClick={handleClick} className="boton-calcular">
                <IconContext.Provider
                  value={{
                    color: "black",
                    size: "4m",
                    className: "global-class-name",
                  }}
                >
                  <BsCalculator />
                </IconContext.Provider>
              </button>
            </div>
            <h2>Precio Efectivo:{precioFinalEfectivo}</h2>
            <h2>Precio Transferencia:{precioFinaltransferencia}</h2>
          </div>

          <div className="row">
            <h2 className="mini-titulo-producto">Producto 2</h2>

            <DropdownProducto selected={selected1} setSelected={setSelected1} />
            <DropdownBordado bordaded={bordaded1} setBordaded={setBordaded1} />
            <DropdownTela tela={tela1} setTela={setTela1} />
            <input
              className="cantidad-cajita"
              type="text"
              placeholder="Cantidad de unidades"
              id="cantidad"
              onChange={handleChange1}
              value={cantidad1}
            />
            <div onClick={handleClick1} className="boton-container">
              <button onClick={handleClick1} className="boton-calcular">
                <IconContext.Provider
                  value={{
                    color: "black",
                    size: "4m",
                    className: "global-class-name",
                  }}
                >
                  <BsCalculator />
                </IconContext.Provider>
              </button>
            </div>
            <h2>Precio Efectivo: {precioFinalEfectivo1}</h2>
            <h2>Precio Transferencia: {precioFinaltransferencia1}</h2>
          </div>

          <div className="row">
            <h2 className="mini-titulo-producto">Producto 3</h2>

            <DropdownProducto selected={selected2} setSelected={setSelected2} />
            <DropdownBordado bordaded={bordaded2} setBordaded={setBordaded2} />
            <DropdownTela tela={tela2} setTela={setTela2} />
            <input
              className="cantidad-cajita"
              type="text"
              placeholder="Cantidad de unidades"
              id="cantidad"
              onChange={handleChange2}
              value={cantidad2}
            />
            <div onClick={handleClick2} className="boton-container">
              <button onClick={handleClick2} className="boton-calcular">
                <IconContext.Provider
                  value={{
                    color: "black",
                    size: "4m",
                    className: "global-class-name",
                  }}
                >
                  <BsCalculator />
                </IconContext.Provider>
              </button>
            </div>
            <h2>Precio Efectivo: {precioFinalEfectivo2}</h2>
            <h2>Precio Transferencia: {precioFinaltransferencia2}</h2>
          </div>
        </div>
      </div>

      <div className="final-section">
        <div className="izq-final-section">
          <h1>PRECIO FINAL</h1>
          <h1 className="senia-para-comenzar">SEÑA PARA COMENZAR</h1>
          <div className="same-line">
            <h1>CANTIDAD DE JUGADORES</h1>
            <input
              className="input-cantidad-jugadores"
              type="text"
              placeholder="Unidades"
              id="personas"
              onChange={handleChangePersona}
              value={personas}
            />
            <button
              onClick={handleClickPersona}
              className="button-calculator-personas"
            >
              <IconContext.Provider
                value={{
                  color: "black",
                  size: "2em",
                  className: "global-class-name",
                }}
              >
                <BsCalculator />
              </IconContext.Provider>
            </button>
          </div>
        </div>
        <div className="der-final-section">
          <div className="transferencia-final-section">
            <h1>TRANSFERENCIA</h1>
            <h1>
              {precioFinaltransferencia +
                precioFinaltransferencia1 +
                precioFinaltransferencia2}
            </h1>
            <h1>
              {" "}
              {Math.round(
                ((precioFinaltransferencia +
                  precioFinaltransferencia1 +
                  precioFinaltransferencia2) *
                  0.6 *
                  100) /
                  100
              )}
            </h1>
            <h1>
              {Math.round(porPersonaTransferencia * 100) / 100} por persona
            </h1>
          </div>
          <div className="efectivo-final-section">
            <h1>EFECTIVO</h1>
            <h1>
              {precioFinalEfectivo +
                precioFinalEfectivo1 +
                precioFinalEfectivo2}
            </h1>
            <h1>
              {" "}
              {Math.round(
                ((precioFinalEfectivo +
                  precioFinalEfectivo1 +
                  precioFinalEfectivo2) *
                  0.6 *
                  100) /
                  100
              )}
            </h1>
            <h1>{Math.round(porPersonaEfectivo * 100) / 100} por persona</h1>
          </div>
        </div>
      </div>
      <div className="button-div-comencemos">
        <button className="comencemos-button">COMENCEMOS!</button>
      </div>
      
    </div>
  );
}

export default CalculadorDePrecios;
