const reviews = [
    {
      id: 1,
      name: 'SAUCE GG',
      job: 'Compromiso',
      image:
        'imagen1',
      text:
        '"Muchas gracias ya contamos con las remeras para sauce sports. Estábamos ansiosos esperando. Mil gracias. Un gusto"',
    },
    {
      id: 2,
      name: 'Guerreros de Fuego',
      job: 'Calidad',
      image:
        'imagen2',
      text:
        '"Buenas, te quiero comunicar que llegó todo bien, muy conformes con las remeras y seguramente te voy a hacer otro pedido grande con los cuatro modelos"',
    },
    {
      id: 3,
      name: 'GORILAS',
      job: 'Nivel',
      image:
        'imagen3',
      text:
        '"Uh genial quedaron a un nivel espectacular"',
    },
  ];
  
  export default reviews;
  