import React from "react";
import "../styles/footerDrop.css";
import dropWelcome from "../imagenes/dropVECTOR1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <div className="container-footer-drop">
      <div className="footer-information-drop">
        <div className="logo-de-bloob-izquierda-drop">
          <img className="bloob-logo-abajo-drop" src={dropWelcome}></img>
        </div>
        <div className="texto-footer-information-drop">
          <h2 className="footer-info-text-drop">
            Calle 44 entre 8 y 9 n "659" Entrepiso. La Plata, Buenos Aires,
            Argentina.
          </h2>
          <h2 className="footer-info-text-drop">Horario de 9 a 18hs de Lunes a Viernes.</h2>
          <div className="ig-y-email-drop">
            <h2 className="footer-info-text-drop">bloobargentina@gmail.com</h2>
            <a
              className="logito-ig-drop"
              href="https://www.instagram.com/drop.arg/"
              target="_blank"
            >
              <div className="redes-sociales-footer-drop">
                <a
                  className="logito-ig-drop"
                  href="https://www.instagram.com/drop.arg/"
                  target="_blank"
                >
                    <FontAwesomeIcon
                      size="xl"
                      icon={faInstagram}
                      color="black"
                    />
                </a>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
