import React from 'react'
import catalogoDeDrop from '..//pdfs/CATALOGO DE PRECIOS.pdf'
import "../styles/pdfs.css"

const CatalogoPreciosPdf = () => {
  return (
    <div>
      <div className='pdf-container'>
      <iframe id="myPDF" src={catalogoDeDrop} frameBorder="0"> </iframe>

      </div>
    </div>
  )
}

export default CatalogoPreciosPdf