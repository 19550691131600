import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import App from "../App";
import "../styles/welcomepage.css";

import BloobImage from "../imagenes/logobloobetiquetas-modified.png";
import DropImage from "../imagenes/dropVECTOR1.png";

import Yellow from "../imagenes/dropColor.png";
import Black from "../imagenes/black.jpeg";

import White from "../imagenes/white.jpg";

import futbolBackground from "../imagenes/PAGINA WEB JB/producto1drop.jpg";
import gamerBackground from "../imagenes/imagenBloob.png";

function WelcomePage(props) {
  return (
    <div>
      <div className="container">
        <div className="left-image-drop">
          <div className="change-image-container-left">
            <Link to="/drop/home">
              <img className="midle-image-left" src={Yellow} />
              <img className="front-image-left" src={DropImage} />
              <img className="back-image-left" src={futbolBackground} />
            </Link>
          </div>

          <div className="left-text">CAMISETAS DE DEPORTE</div>
        </div>

        <div className="right-image-bloob">
          <div className="change-image-container">
            <Link to="/bloob/home">
              <img className="midle-image-right" src={Black} />
              <img className="front-image-right" src={BloobImage} />
              <img className="back-image-right" src={gamerBackground} />
            </Link>
          </div>

          <div className="right-text">CAMISETAS DE ESPORTS</div>
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;
