import React from "react";
import logoMiniatura from "../imagenes/bloobWelcome50px.jpeg";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "../styles/navbarDrop.css";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { BsCart3 } from "react-icons/bs";

function Navbar(props) {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <div>
        <div className="navigation-bar" style={props.style}>
          <div className="logo-bloob">
            <div className="img-logo-bloob">
              <Link to="/">
                <img className="imagen-agrandar-drop" src={props.imagen} />
              </Link>
            </div>
          </div>
          <nav ref={navRef} className="menu-drop">
            <Link to="/drop/home">HOME</Link>
            <Link to="/drop/productos">PRODUCTOS</Link>
            {/*
                          <Link to="/calculadora-de-precios-drop">PRESUPUESTO</Link>
              */}
            <Link to="/drop/tracking">TRACKING</Link>
            {/*
            <Link to="/editor-de-camisetas">CREA TU CAMISETA</Link>
              
              */}
            <Link to="/drop/quienes-somos">QUIÉNES SOMOS</Link>
            <Link to="/drop/preguntas-frecuentes">PREGUNTAS FRECUENTES</Link>

            {/*
            aca tengo el carrito para testear
            */}
            {/* */}

            <button className="nav-btn-drop nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>
          {/**
          
          <div className="div-de-carrito-logo">
            <Link to="/drop/cart">
              <BsCart3 />
            </Link>
          </div>
             */}

          <button className="nav-btn-drop" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
