import React, { useState } from "react";
import data from "../informacion/PreguntasFrecuentesData";
import SingleQuestion from "..//components/PreguntaFrecuente";
import "../styles/PreguntasFrecuentes.css";
import { motion } from "framer-motion";

function PreguntasFrecuentesAca() {
  const [questions, setQuestions] = useState(data);

  return (
    <main>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ delay: 0.3 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="container-de-preguntas"
      >
        <h3 className="titulo-de-page preguntas-frec-titulo">
          PREGUNTAS FRECUENTES
        </h3>
        <section className="info">
          {questions.map((question) => {
            return <SingleQuestion key={question.id} {...question} />;
          })}
        </section>
      </motion.div>
    </main>
  );
}

export default PreguntasFrecuentesAca;
