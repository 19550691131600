import React, { useEffect } from "react";
import "../styles/QuienesSomos.css";
import quienesSomosimagen from "../imagenes/quienesSomos.png";
import { motion } from "framer-motion";

function QuienesSomosBloob() {
  return (
   <motion.div
    initial={{ opacity: 0 }}
    transition={{ delay: 0.3 }}
    whileInView={{ opacity: 1 }}
    exit={{ opacity: 0 }} className="quienes-somos-container">
      <h1 className="quienes-somos-title">QUIÉNES SOMOS</h1>
      <h3 className="quienes-somos-description">
        {" "}
        Siempre hemos tenido el sueño de contar con una camiseta única para
        nuestro grupo de amigos, una prenda que nos permita compartir cada
        partida de eSports sintiéndonos parte de un equipo, con unos colores que
        nos identifiquen, formando una comunidad y una verdadera familia.
        <br></br>
        <br></br>Después de buscar incansablemente, no encontramos lo que
        realmente estábamos buscando en ninguna marca. No lográbamos encontrar
        esa sensación de pertenecer a un espacio donde todos estemos en la misma
        dirección, donde nos sintamos satisfechos y escuchados para poder crear
        la camiseta de nuestros sueños.<br></br>
        <br></br> Decidimos dejar de buscar y tomar la iniciativa de crear
        nuestra propia familia, con un amor y cariño infinitos desde nuestros
        inicios. Nuestro objetivo siempre ha sido crear un producto que
        identifique a cada uno de nuestros clientes que alguna vez soñaron con
        tener la camiseta perfecta.<br></br>
        <br></br> Ha llegado el momento de que tus deseos se hagan realidad.
        Estamos aquí para crear la camiseta que siempre has soñado para tu club,
        equipo o comunidad, para que realmente te sientas parte de una gran
        familia.<br></br>
        <br></br> Cada diseño es creado con el mismo cariño y dedicación, como
        si fuera el primero y el único, porque así es como empezamos este
        hermoso camino.
      </h3>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ delay: 0.3 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="imagen-container-qs"
      >
        <img className="imagen-faq" src={quienesSomosimagen} />
      </motion.div>
    </motion.div>
  );
}

export default QuienesSomosBloob;
