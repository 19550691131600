const questions = [
  {
    id: 1,
    title: "¿Cómo comprar?",
    info: `Podés elegir cualquier producto dentro de nuestro catálogo, seleccionar
        el tipo de tela, cuello y personalizar tu producto al 100%. Para
        realizar el pedido, comunicate vía WhatsApp, nos envias tu idea y
        te preparamos un boceto 3D sin ningún tipo de costo para enviar a tu
        equipo.`,
  },
  {
    id: 2,
    title: "¿Cuánto demora?",
    info: `La demora depende del producto. Para camisetas sublimadas son 20-25 días hábiles. En caso de agregar bordados o vinilos, demoran 30-35 días hábiles. Y para productos como camisetas de baseball, chombas y buzos, la demora es de 35-40 días hábiles.`,
  },
  {
    id: 3,
    title: "¿Cómo envío mi diseño?",
    info: `Te comunicás por WhatsApp, nos enviás una imagen del diseño
        que tenés en mente y nuestros diseñadores te lo preparan exactamente
        igual a lo que estás buscando. Lo ideal es enviar el escudo del equipo,
        el diseño y todos los sponsors en la mejor calidad posible.
        Formato PDF o PNG.`,
  },
  {
    id: 4,
    title: "¿Cómo son los formatos de pago?",
    info: `Primero, el pedido se seña con un monto del 60% del valor del mismo para poder comenzar con la producción.
        Una vez finalizado, se abona el restante.
        Los pagos se pueden realizar en efectivo, por transferencia o MercadoPago. En caso de abonarse en efectivo en nuestro local, se obtiene un descuento.`,
  },
  {
    id: 5,
    title: "¿Cómo es el seguimiento de mi pedido?",
    info: `Cargando el código de tu pedido en la sección TRACKING, podés seguir el estado del mismo.
        Recordá que el proceso productivo tiene 4 etapas y tienen una demora de 7-10 días en adelante según la complejidad del producto.
        Las etapas son (Diseño - Sublimación - Corte - Confección - Pedido Listo)`,
  },
  {
    id: 5,
    title: "Si ya tengo el diseño en vector, ¿cómo lo debo enviar?",
    info: `Te comunicás por WhatsApp comentando que tu modelo está listo para enviarlo en PDF o en un archivo tipo .ai por correo electrónico así los diseñadores lo aplican a nuestros bocetos 3D.`,
  },
];
export default questions;
