import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "../styles/PreguntaFrecuente.css";

const Question = ({ title, info }) => {
  const [showInfo, setShowInfo] = useState(false);

  function mostradorDePreguntas() {
    setShowInfo(!showInfo);
  }

  return (
    <article className="question">
      <header className="titulo-y-boton">
        <h4 className="titulo-faq-ques" onClick={mostradorDePreguntas}>
          {title}
        </h4>
      </header>
      {<p className={ showInfo ? 'respuesta-pregunta open' : 'respuesta-pregunta'}>{info}</p>}
    </article>
  );
};

export default Question;
