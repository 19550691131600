import React from "react";
import "../styles/productoCuadriculaIndividual.css";
import { Link, Navigate } from "react-router-dom";

function ProductoCuadriculaIndividual(props) {
  return (
    <div>
      <div className="column">
        <Link to={"/bloob/productos/" + props.id}>
          <div className="container-img-btn">
            <img className="imagen-producto-ind" src={props.image} />
            <div>
              <Link to={"/bloob/productos/" + props.id}>
                <button className="button-ver-producto">VER PRODUCTO</button>
              </Link>
            </div>
          </div>

          <h2 className="titulo-producto-ind">{props.titulo}</h2>
        </Link>
      </div>
    </div>
  );
}

export default ProductoCuadriculaIndividual;
