import React from "react";
import "../styles/footer.css";
import bloobWelcome01 from "../imagenes/bloobWelcome01.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <div className="container-footer">
      <div className="footer-information">
        <div className="logo-de-bloob-izquierda">
          <img className="bloob-logo-abajo" src={bloobWelcome01}></img>
        </div>
        <div className="texto-footer-information">
          <h2 className="footer-info-text">
            Calle 44 entre 8 y 9 n "659" Entrepiso. La Plata, Buenos Aires,
            Argentina.
          </h2>
          <h2 className="footer-info-text">Horario de 9 a 18hs de Lunes a Viernes.</h2>
          <div className="ig-y-email">
            <h2 className="footer-info-text">bloobargentina@gmail.com</h2>
            <a
              className="logito-ig"
              href="https://www.instagram.com/bloob.arg/"
              target="_blank"
            >
              <div className="redes-sociales-footer">
                <a
                  className="logito-ig"
                  href="https://www.instagram.com/bloob.arg/"
                  target="_blank"
                >
                    <FontAwesomeIcon
                      size="xl"
                      icon={faInstagram}
                      color="white"
                    />
                </a>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
