import React from 'react'
import { motion } from "framer-motion";

function Colaboraciones(props) {
  return (
    <motion.div
    initial={{ opacity: 0 }}
    transition={{ delay: 0.8 }}
    whileInView={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className='div-outside-colabs'>
        <div className='imgColaboraciones'>
            <img className='imagen-colaboracion' src={props.colaboracionImagen}/>
        </div>
    </motion.div>
  )
  
}

export default Colaboraciones