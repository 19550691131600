import React from "react";
import ProductocuadriculaIndividual from "../components/ProductoCuadriculaIndividual";
import "../styles/cuadriculaproductos.css";
import catalogo from "../informacion/catalogo";
import producto1 from "../imagenes/productosIndividuales/productoIndividual1.png";
import producto2 from "../imagenes/productosIndividuales/productoIndividual2.png";
import producto3 from "../imagenes/productosIndividuales/productoIndividual3.png";
import producto4 from "../imagenes/productosIndividuales/productoIndividual4.png";
import producto5 from "../imagenes/productosIndividuales/productoIndividual5.png";
import producto6 from "../imagenes/productosIndividuales/productoIndividual6.png";
import producto7 from "../imagenes/productosIndividuales/productoIndividual7.png";
import producto8 from "../imagenes/productosIndividuales/productoIndividual8.png";

//prod1
import producto1Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-1.jpg"
import producto2Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-2.jpg"
import producto3Baseball from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/baseball-3.jpg"
//prod2
import camisetabordada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada1.jpg"
import camisetabordada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada2.jpg"
import camisetabordada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordada3.jpg"
//prod3
import camisetabordadayvinilada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada1.jpg"
import camisetabordadayvinilada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada2.jpg"
import camisetabordadayvinilada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetabordadayvinilada3.jpg"
//prod4
import camisetafemenina1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina1.jpg"
import camisetafemenina2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina2.jpg"
import camisetafemenina3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetafemenina3.jpg"
//prod5
import camisetanio1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio1.jpg"
import camisetanio2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio2.jpg"
import camisetanio3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetanio3.jpg"
//prod6
import camisetasublimada1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada1.jpg"
import camisetasublimada2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada2.jpg"
import camisetasublimada3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/camisetasublimada3.jpg"
//prod7
import conjuntos1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos1.jpg"
import conjuntos2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos2.jpg"
import conjuntos3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/conjuntos3.jpg"  
//prod8
import sudadera1 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera1.jpg"
import sudadera2 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera2.jpg"
import sudadera3 from "../imagenes/PAGINA WEB JB/FOTOS PRODUCTOS DROP/sudadera3.jpg" 

const CuadriculaProductos = ({ catalogoLista }) => {
  var firstHalf = catalogoLista.slice(0,4)
  var secondHalf = catalogoLista.slice (4,8)
  return (
    <div>
      <div className="container-productos">
        <div className="row">
          {firstHalf?.map((productoItem) => {
            const {
              id,
              title,
              price,
              pricea,
              priceb,
              imgtop,
              imgdownleft,
              imgdownright,
              desc,
            } = productoItem;
            if(title === "CAMISAS DE BASEBALL"){

            return (

              <div key={id}>
                <ProductocuadriculaIndividual
                  titulo={title}
                  image={producto2Baseball}
                  id={id}
                  customisable="true"
                />
              </div>
            );
          }else if (title === "CAMISETA BORDADA"){
            return(
              

                <div key={id}>
                  <ProductocuadriculaIndividual
                    titulo={title}
                    image={camisetabordada1}
                    id={id}
                    customisable="true"
                  />
                </div>
            )
          }
        else if (title === "CAMISETA BORDADA Y VINILADA"){
          return(
            

              <div key={id}>
                <ProductocuadriculaIndividual
                  titulo={title}
                  image={camisetabordadayvinilada2}
                  id={id}
                  customisable="true"
                />
              </div>
          )
        }
        else if (title === "CAMISETA FEMENINA"){
          return(
            

              <div key={id}>
                <ProductocuadriculaIndividual
                  titulo={title}
                  image={camisetafemenina1}
                  id={id}
                  customisable="true"
                />
              </div>
          )
        }

          })}
        </div>
        

      </div>

      <div className="container-productos">
        <div className="row">
          {secondHalf?.map((productoItem) => {
            const {
              id,
              title,
              price,
              pricea,
              priceb,
              imgtop,
              imgdownleft,
              imgdownright,
              desc,
            } = productoItem;
            if(title === "CAMISETA NIÑO"){

              return (
  
                <div key={id}>
                  <ProductocuadriculaIndividual
                    titulo={title}
                    image={camisetanio1}
                    id={id}
                    customisable="true"
                  />
                </div>
              );
            }else if (title === "CAMISETA SUBLIMADA"){
              return(
                
  
                  <div key={id}>
                    <ProductocuadriculaIndividual
                      titulo={title}
                      image={camisetasublimada1}
                      id={id}                      
                      customisable="true"
                    />
                  </div>
              )
            }
          else if (title === "CONJUNTOS"){
            return(
              
  
                <div key={id}>
                  <ProductocuadriculaIndividual
                    titulo={title}
                    image={conjuntos1}
                    id={id}
                    customisable="true"
                  />
                </div>
            )
          }
          else if (title === "SUDADERA"){
            return(
              
  
                <div key={id}>
                  <ProductocuadriculaIndividual
                    titulo={title}
                    image={sudadera1}
                    id={id}
                    customisable="true"
                  />
                </div>
            )
          }
          })}
        </div>
        

      </div>
      
    </div>
    
  );
};

export default CuadriculaProductos;