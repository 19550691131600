import React, { useState } from "react";
import logoMiniatura from "../imagenes/bloobWelcome50px.jpeg";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "../styles/navbar.css";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";




function Navbar(props) {
  const navRef = useRef();

  //const hideNavbar =() => {
   // navRef.current.classList.toggle("responsive_nav");
  //}

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <div>
        <div className="navigation-bar-bloob" style={props.style}>
          <div className="logo-bloob">
            <div className="img-logo-bloob">
              <Link to="/">
                <img className="imagen-agrandar-drop" src={props.imagen} />
              </Link>
            </div>
          </div>
          <nav ref={navRef} className="menu-bloob">
            {/*className="colaboracionracionracion"*/}
            <Link to="/bloob/home">HOME</Link>
            <Link to="/bloob/productos">PRODUCTOS</Link>
            {
              /*
              <Link to="/calculadora-de-precios">PRESUPUESTO</Link>
            */
            }
            <Link to="/bloob/tracking">TRACKING</Link>
            {
              /*
            <Link to="/editor-de-camisetas">CREA TU CAMISETA</Link>
              
              */
            }
            <Link to="/bloob/quienes-somos">QUIÉNES SOMOS</Link>
            <Link to="/bloob/preguntas-frecuentes">PREGUNTAS FRECUENTES</Link>
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
