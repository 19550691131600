import React from "react";
import { Link } from "react-router-dom";

import "../styles/vermasproductos.css";

function rightProductosButton(infolado) {
  if (infolado == "bloob") {

    return (
      <div className="buttonDiv">
        <Link to="/bloob/productos">
          <button className="buttonProductos">VER MÁS PRODUCTOS</button>
        </Link>
      </div>
    );
  } else if (infolado == "drop") {

    return (
      <div className="buttonDiv">
        <Link to="/drop/productos">
          <button className="buttonProductos">VER MÁS PRODUCTOS</button>
        </Link>
      </div>
    );
  }
}

function Vermasproductosbutton(props) {
  return(
  <div>{rightProductosButton(props.infolado)}</div>
  );
}

export default Vermasproductosbutton;
