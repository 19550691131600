import React from 'react'
import catalogoInteractivo from '..//pdfs/CATALOGO INTERACTIVO.pdf'
import "../styles/pdfs.css"

const CatalogoInteractivo = () => {
  return (
    <div>
      <div className='pdf-container'>
      <iframe id="myPDF" src={catalogoInteractivo} frameBorder="0"> </iframe>

      </div>
    </div>
  )
}

export default CatalogoInteractivo;