import React, { useState } from "react";
import "../styles/Master.css";
import Axios from "axios";

function Master() {
  const [teamName, setTeamName] = useState("");
  const [teamStage, setTeamStage] = useState("");
  const [id, setId] = useState(1);
  const [teamList, setTeamList] = useState([]);
  const [oneTeam, setOneTeam] = useState([]);
  const [newStage, setNewStage] = useState("");
  const [passwordUsr, setPasswordUsr] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const BACKEND_URL = "https://drop-track.onrender.com";

  const addTeam = () => {
    Axios.post(
      BACKEND_URL + "/create",
      {
        teamName: teamName,
        teamStage: teamStage,
      },
      { headers: { Authorization: passwordUsr } }
    ).then((r) => {
      setTeamList([
        ...teamList,
        {
          teamName: teamName,
          teamStage: teamStage,
          code: r.data.code,
        },
      ]);
      document.getElementById("team-name").value = "";

      document.getElementById("team-stage").value = "";
    });
  };

  const getOne = (id) => {
    Axios.get(`${BACKEND_URL}/team/${id}`, {}).then((response) => {
      setTeamName(response.data.teamName);
      setTeamStage(response.data.teamStage);
    });
  };

  const getTeams = () => {
    Axios.get(BACKEND_URL + "/teams", {
      headers: { Authorization: passwordUsr },
    }).then((response) => {
      setTeamList(response.data);
    });
  };

  const updateStage = (code) => {
    Axios.put(
      BACKEND_URL + "/update",
      {
        teamStage: newStage,
        id: code,
      },
      { headers: { Authorization: passwordUsr } }
    ).then((response) => {
      setTeamList(
        teamList.map((val) => {
          return val.code == code
            ? { id: val.code, teamName: val.teamName, teamStage: newStage }
            : val;
        })
      );
    });
  };

  const deleteTeam = (code) => {
    Axios.delete(`${BACKEND_URL}/delete/${code}`, {
      headers: { Authorization: passwordUsr },
    }).then((response) => {
      setTeamList(
        teamList.filter((val) => {
          return val.code != code;
        })
      );
    });
  };

  const checkPassword = (e) => {
    e.preventDefault();
    if (!passwordUsr) {
      setLoginErrorMessage("ingrese una contrasenia");
      return;
    }
    Axios.post(BACKEND_URL + "/check-password", { passwordUsr }).then(
      (response) => {
        setLoggedIn(response.data);
        if (!response.data) {
          setLoginErrorMessage("contrasenia incorrecta");
        }
      }
    );
  };

  if (!loggedIn) {
    return (
      <>
        <div>
          <form className="login-form" onSubmit={checkPassword}>
            <h1 className="titulo-master-data">Ingrese la contraseña</h1>
            <div>
              <input
                className="texto-a-insertar-login"
                type="password"
                placeholder="Password"
                value={passwordUsr}
                onChange={(event) => {
                  setPasswordUsr(event.target.value);
                  setLoginErrorMessage("");
                }}
              />
            </div>
            <p>{loginErrorMessage}</p>
            <input
              type="submit"
              className="button-3 extra-button-tracking"
              value="Buscar"
            />
          </form>
        </div>
      </>
    );
  }

  return (
    <div className="master-container">
      <h1>Master</h1>
      <br />

      <div className="actualizar-container">
        <h2 className="titulo-actualizar">Buscar cliente</h2>
        <div>
          <label>Team código de seguimiento:</label>
          <input
            type="text"
            id="codigo-de-seguimiento"
            onChange={(event) => {
              setId(event.target.value);
            }}
          />
          <button
            onClick={() => {
              getOne(id);
            }}
          >
            {" "}
            Buscar
          </button>
          <div>
            {id} - {teamName} - {teamStage}
          </div>
          <div>
            <select
              id="team-stage"
              name="team-stage"
              onChange={(event) => {
                setNewStage(event.target.value);
              }}
            >
              <option value="Pedido">Pedido</option>
              <option value="Sublimacion">Sublimación</option>
              <option value="Control 1">CONTROL 1</option>
              <option value="Confeccion">Confección</option>
              <option value="Control 2">CONTROL 2</option>
              <option value="Listas sin bordado">Listas sin bordado</option>
              <option value="Bordados">Bordados</option>
              <option value="Lista con Bordado">Lista con Bordado</option>
              <option value="Problema">Problema</option>
            </select>
            <button
              onClick={() => {
                updateStage(id);
              }}
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <br />
      <div className="nuevo-cliente-container">
        <h2 className="titulo-cliente-nuevo">Nuevo cliente</h2>
        <div>
          <label>Team Name:</label>
          <input
            type="text"
            id="team-name"
            onChange={(event) => {
              setTeamName(event.target.value);
            }}
          />
          <br></br>
          <label>Etapa:</label>
          {/*<input
            type="text"
            id="team-stage"
            onChange={(event) => {
              setTeamStage(event.target.value);
            }}
          />
          */}
          <select
            id="team-stage"
            name="team-stage"
            onChange={(event) => {
              setTeamStage(event.target.value);
            }}
          >
            <option value="Pedido">Pedido</option>
            <option value="Sublimacion">Sublimación</option>
            <option value="Control 1">CONTROL 1</option>
            <option value="Confeccion">Confección</option>
            <option value="Control 2">CONTROL 2</option>
            <option value="Listas sin bordado">Listas sin bordado</option>
            <option value="Bordados">Bordados</option>
            <option value="Lista con Bordado">Lista con Bordado</option>
            <option value="Problema">Problema</option>
          </select>
          <button onClick={addTeam}> Agregar Team </button>
        </div>
      </div>
      <br />
      <div className="todos-los-clientes-container">
        <h2 className="titulo-todos-los-clientes"> Todos los clientes</h2>
        <button onClick={getTeams}>Clientes</button>
        <div className="list-container">
          {teamList.map((val, key) => {
            return (
              <>
                <div className="team-in-the-list">
                  <div className="teams-list">
                    {val.code} - {val.teamName}
                  </div>
                  <div className="stage">{val.teamStage}</div>
                  <div>
                    {/*                    <input
                      type="text"
                      placeholder="nuevo estado"
                      onChange={(event) => {
                        setNewStage(event.target.value);
                      }}
                    />*/}
                    <select
                      id="team-stage"
                      name="team-stage"
                      onChange={(event) => {
                        setNewStage(event.target.value);
                      }}
                    >
                      <option value="Pedido">Pedido</option>
                      <option value="Sublimacion">Sublimación</option>
                      <option value="Control1">CONTROL 1</option>
                      <option value="Confeccion">Confección</option>
                      <option value="Control2">CONTROL 2</option>
                      <option value="Listassinbordado">
                        Listas sin bordado
                      </option>
                      <option value="Bordados">Bordados</option>
                      <option value="Listaconbordado">
                        Lista con Bordado
                      </option>
                      <option value="Problema">Problema</option>
                    </select>
                    <button
                      onClick={() => {
                        updateStage(val.code);
                      }}
                    >
                      Actualizar
                    </button>
                    <button
                      onClick={() => {
                        deleteTeam(val.code);
                      }}
                    >
                      Delete Team
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Master;
