import React from 'react'

function Direccion(props) {
  return (
    <div>
        <div className='fraseDireccion'>
            <p className='direccionP'>{props.fraseDireccion}</p>
        </div>
    </div>
  )
}

export default Direccion