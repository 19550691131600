import React, {useContext} from "react";
import CartItem from "./CartItem";
import { ShopContext } from "./Shop-context";
import catalogo from "../informacion/catalogo";

import "../styles/talles.css";

const Talles = () => {
    const { cartItems, getTotalCartAmount } = useContext(ShopContext);

  return (
    <div className="talles-container">
      <h1 className="titulo-talles">Talles</h1>
      <div className="cart-items">
        {catalogo.map((catalogoItem) => {
          if (cartItems[catalogoItem.id] !== 0 || undefined) {
            return <CartItem data={catalogoItem} />;
          }
        })}
      </div>
    </div>
  );
};

export default Talles;
